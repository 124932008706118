import { FACEBOOK_PIXEL_ID } from './constants';

export function init() {
  if (!window.fbq) {
    return;
  }
  window.fbq('init', FACEBOOK_PIXEL_ID);
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (
  name: string,
  options: facebook.Pixel.CustomParameters = {}
) => {
  if (!window.fbq) {
    return;
  }
  window.fbq('track', name, options);
};

export const pageview = () => {
  event('PageView');
};

export const viewContent = (options?: facebook.Pixel.ViewContentParameters) => {
  event('ViewContent', options);
};

export function purchase(options: facebook.Pixel.PurchaseParameters) {
  event('Purchase', options);
}

export function initiateCheckout(
  options: facebook.Pixel.InitiateCheckoutParameters
) {
  event('InitiateCheckout', options);
}
