import firebase from '@lib/firebase';

export enum AuthMethod {
  google = 'google.com',
  facebook = 'facebook.com',
  apple = 'apple.com',
  password = 'password',
}

export type SocialAuthMethod =
  | AuthMethod.google
  | AuthMethod.facebook
  | AuthMethod.apple;

export const authMethods: AuthMethod[] = [
  AuthMethod.google,
  AuthMethod.facebook,
  AuthMethod.apple,
  AuthMethod.password,
];

export function getProviderDisplayName(type: string) {
  switch (type) {
    case AuthMethod.apple: {
      return 'Apple';
    }
    case AuthMethod.facebook: {
      return 'Facebook';
    }
    case AuthMethod.google: {
      return 'Google';
    }
    case AuthMethod.password: {
      return 'email';
    }
    default: {
      throw new Error(`Provider ${type} not supported`);
    }
  }
}

export function getProviderById(type: SocialAuthMethod) {
  switch (type) {
    case AuthMethod.facebook: {
      return new firebase.auth.FacebookAuthProvider();
    }
    case AuthMethod.google: {
      return new firebase.auth.GoogleAuthProvider();
    }
    case AuthMethod.apple: {
      return new firebase.auth.OAuthProvider(type);
    }
    default: {
      throw new Error(`Provider ${type} not supported`);
    }
  }
}

export function isValidSignInMethod(method: string): method is AuthMethod {
  return authMethods.includes(method as AuthMethod);
}

export function isSocialAuthMethod(
  method: AuthMethod
): method is SocialAuthMethod {
  return (
    method === AuthMethod.apple ||
    method === AuthMethod.facebook ||
    method === AuthMethod.google
  );
}
