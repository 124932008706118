import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useToasts } from './toasts';
import { Button } from './ui/Button';

const version = process.env.NEXT_PUBLIC_PKG_VERSION;

export function VersionCheck() {
  const router = useRouter();
  const intervalInSeconds =
    router.query.__VERSION_CHECK_INTERVAL_SECONDS?.toString() || '60';
  const toasts = useToasts();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  useEffect(() => {
    if (newVersionAvailable) {
      return;
    }
    async function versionCheck() {
      const response = await fetch('/api/pkg-version')
        .then((res) => res.json())
        .catch(() => {
          // noop
        });
      const result = response as { version: string } | undefined;
      if (result && result.version && result.version !== version) {
        setNewVersionAvailable(true);
      }
    }
    const intervalId = setInterval(() => {
      versionCheck();
    }, parseInt(intervalInSeconds) * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [newVersionAvailable, intervalInSeconds]);
  useEffect(() => {
    if (!newVersionAvailable) {
      return;
    }
    toasts.addToast({
      type: 'info',
      ttl: 0,
      text: (
        <div
          data-testid="NewVersionToast"
          className="flex items-center justify-between space-x-2"
        >
          <div>We have successfully updated Atelie!</div>
          <Button
            size="sm"
            variant="primaryYellow"
            onClick={() => window.location.reload()}
          >
            Update
          </Button>
        </div>
      ),
    });
  }, [newVersionAvailable]);
  return null;
}
