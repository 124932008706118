export enum userRole {
  admin = 'admin',
}

export function getRole(
  claims: Record<string, string> | undefined
): userRole | undefined {
  if (!claims) {
    return;
  }
  switch (claims.role) {
    case userRole.admin: {
      return userRole.admin;
    }
    default: {
      return;
    }
  }
}
