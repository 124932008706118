import { AuthMethod } from '@components/auth/utils';
import { ImpressionArtworkItemFragment } from '@generated/codegen';
import firebase from '@lib/firebase';
import uniqBy from 'lodash/uniqBy';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useAnalyticsConsent } from './analytics-consent';
import { useUserDoc, useUserRole } from './authentication';
import * as fbq from './fpixel';

type EventParams = firebase.analytics.EventParams;
type Item = firebase.analytics.Item & {
  item_id: string;
  studio_id?: string;
};

function authMethodToTrack(
  value: AuthMethod
): 'facebook' | 'google' | 'apple' | 'email_and_password' {
  switch (value) {
    case AuthMethod.apple: {
      return 'apple';
    }
    case AuthMethod.facebook: {
      return 'facebook';
    }
    case AuthMethod.google: {
      return 'google';
    }
    case AuthMethod.password: {
      return 'email_and_password';
    }
  }
}

export const trackLoginEvent = (method: AuthMethod) => {
  firebase.analytics().logEvent(firebase.analytics.EventName.LOGIN, {
    method: authMethodToTrack(method),
  });
};

export const trackSignUpEvent = (method: AuthMethod) => {
  firebase.analytics().logEvent(firebase.analytics.EventName.SIGN_UP, {
    method: authMethodToTrack(method),
  });
};

export const trackChatMessageEvent = () => {
  firebase.analytics().logEvent('SendMessage');
};

export const trackViewItemList = ({
  itemListId,
  itemListName,
  items,
}: {
  itemListId: string;
  itemListName?: string;
  items: Item[];
}) => {
  firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM_LIST, {
    item_list_id: itemListId,
    item_list_name: itemListName,
    items,
  });
};

export const trackSelectItemList = ({
  itemListId,
  itemListName,
  items,
}: {
  itemListId: string;
  itemListName?: string;
  items: EventParams['items'];
}) => {
  firebase.analytics().logEvent(firebase.analytics.EventName.SELECT_ITEM, {
    item_list_id: itemListId,
    item_list_name: itemListName,
    items,
  });
};

export const trackViewItem = ({
  item,
  value,
  currency,
}: {
  value: number | undefined;
  currency: string;
  item: Item;
}) => {
  fbq.viewContent({
    content_ids: [item.item_id],
    content_name: item.item_name,
    content_type: 'product',
    value,
    currency,
  });
  firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM, {
    currency,
    value,
    items: [item],
  });
};

export const trackBeginCheckout = ({
  currency,
  value,
  item,
}: {
  value: number;
  currency: string;
  item: Item;
}) => {
  fbq.initiateCheckout({
    currency,
    value,
    num_items: 1,
    content_name: item.item_name,
    content_ids: [item.item_id],
  });
  firebase.analytics().logEvent(firebase.analytics.EventName.BEGIN_CHECKOUT, {
    currency,
    value,
    items: [item],
  });
};

export const trackPurchase = ({
  transaction_id,
  value,
  currency,
  item,
}: {
  transaction_id: string;
  value: number;
  currency: string;
  item: Item;
}) => {
  fbq.purchase({
    value,
    currency,
    order_id: transaction_id,
    content_type: 'product',
    content_ids: [item.item_id],
  });
  firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, {
    transaction_id,
    value,
    currency,
    items: [item],
  });
};

export const trackSearch = ({ search_term }: { search_term: string }) => {
  fbq.event('Search', { search_string: search_term });
  firebase.analytics().logEvent(firebase.analytics.EventName.SEARCH, {
    search_term,
  });
};

export const trackFavouriteArtwork = ({ artworkId }: { artworkId: string }) => {
  firebase.analytics().logEvent('FavoriteArtwork', {
    ArtworkID: artworkId,
  });
};

export const trackRemoveFavouriteArtwork = ({
  artworkId,
}: {
  artworkId: string;
}) => {
  firebase.analytics().logEvent('RemoveFavoriteArtwork', {
    ArtworkID: artworkId,
  });
};

export const trackFavouriteStudio = ({ studioId }: { studioId: string }) => {
  firebase.analytics().logEvent('FavoriteStudio', {
    StudioId: studioId,
  });
};

export const trackRemoveFavouriteStudio = ({
  studioId,
}: {
  studioId: string;
}) => {
  firebase.analytics().logEvent('RemoveFavoriteStudio', {
    StudioId: studioId,
  });
};

export const trackShareItem = ({
  method,
  content_type,
  item_id,
}: {
  method?: string;
  content_type: 'artwork' | 'studio';
  item_id: string;
}) => {
  firebase.analytics().logEvent(firebase.analytics.EventName.SHARE, {
    method,
    content_type,
    item_id,
  });
};

export const setUserId = (userId: string | null) => {
  // null is allowed here and will reset the userId
  firebase.analytics().setUserId(userId as string);
};

const handleRouteChange = (url: string) => {
  firebase.analytics().setCurrentScreen(url);
  fbq.pageview();
};

export const useAnalyticsSetup = () => {
  const router = useRouter();
  const { userDoc, loading: userDocLoading } = useUserDoc();
  const { role, loading: userRoleLoading } = useUserRole();
  const { consented } = useAnalyticsConsent();
  const loading = userDocLoading || userRoleLoading;
  useEffect(() => {
    firebase.analytics().setAnalyticsCollectionEnabled(consented);
    if (consented) {
      fbq.init();
    }
  }, [consented]);
  useEffect(() => {
    if (loading) {
      return;
    }
    firebase.analytics().setUserProperties({
      isArtist: userDoc ? Boolean(userDoc.isArtist) : null,
      role: role || undefined,
    });
  }, [userDoc, role, loading]);
  useEffect(() => {
    if (!consented) {
      return;
    }
    // This pageview only trigger first time (it is important for Pixel to have real information)
    fbq.pageview();
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, consented]);
};

export function artworkToItem(
  artwork: ImpressionArtworkItemFragment,
  index?: number
): Item {
  const item: Item = {
    item_id: artwork.id,
    item_name: artwork.title,
    price: artwork.formattedPrice?.value,
    quantity: 1,
    item_category: 'Artwork',
  };
  if (index !== undefined) {
    item.index = index;
  }
  if (artwork.studio) {
    item.item_brand = artwork.studio.name || artwork.studio.id;
    item.studio_id = artwork.studio.id;
  }
  return item;
}

export function useArtworkImpressionTracker(
  itemListId: string,
  itemListName: string
) {
  const items = useRef<Item[]>([]);
  const trackedItemIds = useRef(new Set<string>());

  function trackAndResetItems() {
    const untrackedItems: Item[] = [];
    for (const item of items.current) {
      if (!trackedItemIds.current.has(item.item_id)) {
        untrackedItems.push(item);
        trackedItemIds.current.add(item.item_id);
      }
    }
    if (untrackedItems.length > 0) {
      trackViewItemList({
        itemListId,
        itemListName,
        items: untrackedItems,
      });
    }
    items.current = [];
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      trackAndResetItems();
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    function listener() {
      trackAndResetItems();
    }
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, []);
  const onArtworkImpression = useCallback(function addArtworkImpression(
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) {
    items.current = uniqBy(
      [...items.current, artworkToItem(artwork, index)],
      'item_id'
    );
  },
  []);
  const onArtworkSelect = useCallback(
    (artwork: ImpressionArtworkItemFragment, index: number) => {
      trackSelectItemList({
        itemListId,
        itemListName,
        items: [artworkToItem(artwork, index)],
      });
    },
    []
  );
  return {
    onArtworkImpression,
    onArtworkSelect,
  };
}
