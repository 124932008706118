import addYears from 'date-fns/addYears';
import { Cookies, useCookies } from 'react-cookie';
import { ANALYTICS_CONSENT_COOKIE_NAME } from './constants';

type ConsentValue = 'true' | 'false';

export function useAnalyticsConsent() {
  const [cookies, setCookie] = useCookies([ANALYTICS_CONSENT_COOKIE_NAME]);
  const value: ConsentValue | undefined =
    cookies[ANALYTICS_CONSENT_COOKIE_NAME];
  const actionRequired = value === undefined;
  const consented = value === 'true';
  function setConsent(consent: ConsentValue) {
    setCookie(ANALYTICS_CONSENT_COOKIE_NAME, consent, {
      path: '/',
      expires: addYears(new Date(), 1),
      sameSite: 'none',
      secure: true,
    });
  }
  function removeConsent() {
    setConsent('false');
    // reload the page to make sure Facebook pixel is not initialized
    window.location.reload();
  }
  return {
    actionRequired,
    consented,
    removeConsent,
    setConsent,
  };
}

export function getConsented() {
  const cookies = new Cookies();
  const value = cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) as
    | ConsentValue
    | undefined;
  return value === 'true';
}
