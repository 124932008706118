import {
  GET_EVENTS,
  LOADING_EVENTS,
  EVENT_ATTENDIE_DATA,
  GET_EXHIBITIONS,
  LOADING_EXHIBITIONS
} from 'actions/events';

const initialState = {
  events: {
    isLoading: true,
    data: []
  },
  exhibitions: {
    isLoading: true,
    data: []
  },
  attendieData: null
};
export default function Events(state = initialState, action) {
  switch (action.type) {
    case GET_EXHIBITIONS: {
      return { exhibitions: { isLoading: false, data: action.payload }, events: state.events, attendieData: state.attendieData };
    }
    case GET_EVENTS: {
      return { events: { isLoading: false, data: action.payload }, exhibitions: state.exhibitions, attendieData: state.attendieData };
    }
    case LOADING_EVENTS: {
      return { events: initialState.events, exhibitions: state.exhibitions, attendieData: state.attendieData };
    }
    case LOADING_EXHIBITIONS: {
      return { events: state.events, exhibitions: initialState.exhibitions, attendieData: state.attendieData };
    }
    case EVENT_ATTENDIE_DATA: {
      return {
        ...state,
        attendieData: action.payload
      }
    }
    default:
      return state;
  }
}
