import { FETCH_FAVORITES, FETCH_FOLLOWING } from 'actions/favorites'

const initialState = {
  isLoading: true,
  artists: [],
  artworks: []
}
export default function Favorites(state = initialState, action) {
  switch(action.type) {
    case FETCH_FAVORITES: {return { ...state, ...action.payload };}
    case FETCH_FOLLOWING: {return { ...state, ...action.payload };}
    default: return state;
  }
}