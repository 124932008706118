import React, { createContext, useContext, useReducer, useState } from 'react';
import { Modal } from '../ui/Modal';
import { useAuthUser } from '@lib/authentication';
import { useEffect } from 'react';
import { Note } from '@components/ui/Note';
import { AuthUI } from './AuthUI';
import { useRouter } from 'next/router';

export type AuthType = 'login' | 'signup';

type Action =
  | { type: 'OPEN_AUTH_MODAL'; payload: { description?: string } }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_TYPE'; payload: { type: AuthType } }
  | {
      type: 'OPEN_ERROR_MODAL';
      payload: { description?: string; isError?: boolean };
    };

type State = {
  open: boolean;
  type: AuthType;
  /**
   * Describe why the user need to Sign in. Eg "Sign in to send a message"
   */
  description: string | undefined;
  isError: boolean | undefined;
};

const initialState: State = {
  open: false,
  type: 'login',
  description: undefined,
  isError: undefined,
};

function authModalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_AUTH_MODAL': {
      return {
        ...state,
        open: true,
        description: action.payload.description,
      };
    }
    case 'CLOSE_MODAL': {
      return initialState;
    }
    case 'SET_TYPE': {
      return {
        ...state,
        type: action.payload.type,
      };
    }
    case 'OPEN_ERROR_MODAL': {
      return {
        ...state,
        open: true,
        description: action.payload.description,
        isError: action.payload.isError,
      };
    }
    default:
      return state;
  }
}

export const AuthModalContext = createContext<
  [State, React.Dispatch<Action>] | undefined
>(undefined);

export const AuthModalProvider = (props: { children: React.ReactNode }) => {
  const reducer = useReducer(authModalReducer, initialState);
  return (
    <AuthModalContext.Provider value={reducer}>
      {props.children}
    </AuthModalContext.Provider>
  );
};

export function useAuthModal(): State & {
  dispatch: React.Dispatch<Action>;
  openAuthModal: (description?: string, isError?: boolean) => void;
  closeAuthModal: () => void;
} {
  const context = useContext(AuthModalContext);
  if (context === undefined) {
    throw new Error('useAuthModal must be used within a AuthModalContext');
  }
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({ type: 'CLOSE_MODAL' });
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  const [state, dispatch] = context;
  return {
    ...state,
    dispatch,
    openAuthModal: (description?: string, isError?: boolean) => {
      isError
        ? dispatch({
            type: 'OPEN_ERROR_MODAL',
            payload: { description, isError },
          })
        : dispatch({ type: 'OPEN_AUTH_MODAL', payload: { description } });
    },
    closeAuthModal: () => {
      dispatch({ type: 'CLOSE_MODAL' });
    },
  };
}

export function AuthModal() {
  const { open, description, isError, type, closeAuthModal, dispatch } =
    useAuthModal();
  const [switchingToType, setSwitchingToType] = useState<AuthType | null>(null);
  const { authenticated } = useAuthUser();
  useEffect(() => {
    if (authenticated) {
      closeAuthModal();
    }
  }, [authenticated]);

  if (isError) {
    return (
      <Modal
        open={open}
        onClose={() => {
          closeAuthModal();
        }}
        switching={Boolean(switchingToType)}
        onAnimationComplete={(definition) => {
          if (definition === 'switching' && switchingToType) {
            dispatch({ type: 'SET_TYPE', payload: { type: switchingToType } });
            setSwitchingToType(null);
          }
        }}
      >
        {description && (
          <Modal.Description as="div">
            <Note type="error" className="mb-4">
              {description}
            </Note>
          </Modal.Description>
        )}
      </Modal>
    );
  } else {
    return (
      <Modal
        open={open}
        onClose={() => {
          closeAuthModal();
        }}
        switching={Boolean(switchingToType)}
        onAnimationComplete={(definition) => {
          if (definition === 'switching' && switchingToType) {
            dispatch({ type: 'SET_TYPE', payload: { type: switchingToType } });
            setSwitchingToType(null);
          }
        }}
      >
        <Modal.Title className="sr-only">
          {type === 'login' ? 'Sign in' : 'Sign up'}
        </Modal.Title>
        {description && (
          <Modal.Description as="div">
            <Note type="info" className="mb-4">
              {description}
            </Note>
          </Modal.Description>
        )}
        <AuthUI
          type={type}
          onLoginClick={(event) => {
            event.preventDefault();
            setSwitchingToType('login');
          }}
          onSignupClick={(event) => {
            event.preventDefault();
            setSwitchingToType('signup');
          }}
        />
      </Modal>
    );
  }
}
