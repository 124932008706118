import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import type { AppProps } from 'next/app';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Provider } from 'react-redux';
import {
  FirebaseAuthContextProvider,
  FirebaseUserDocContextProvider,
  AuthSetup,
} from '../lib/authentication';
import { useStore } from '@lib/store';
import { Intercom } from '@components/common/Intercom';
import { DefaultSeo } from 'next-seo';

// legacy css
import '../src/styles/semantic.min.css';
import '../src/styles/atelier.min.css';

// new cool tailwind css
import '../styles/index.css';

import { CookiesProvider } from 'react-cookie';
import { useApollo } from '@lib/graphql/client';
import { ApolloProvider } from '@apollo/client';
import {
  FACEBOOK_DOMAIN_VERIFICATION,
  WEB_ROOT_URL,
  SITE_NAME,
  ARTWORK_MODAL_QUERY_KEY,
  LOCALE,
} from '@lib/constants';
import { ToastProvider, Toasts } from '@components/toasts';
import { useRouter } from 'next/router';
import { VersionCheck } from '@components/VersionCheck';
import { IntlProvider } from 'react-intl';
import { AuthModal, AuthModalProvider } from '@components/auth/AuthModal';
import { useAnalyticsSetup } from '@lib/analytics';

function AppSetup({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  useAnalyticsSetup();
  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      `,
        }}
      />
      {/* Do not show AuthModal if ARTWORK_MODAL_QUERY_KEY is set, as ArtworkModal handles AuthModal itself */}
      {!router.query[ARTWORK_MODAL_QUERY_KEY] && <AuthModal />}
      {children}
    </>
  );
}

export default function MyApp({
  Component,
  pageProps,
  err,
}: AppProps & {
  err?: Error;
  Component: AppProps['Component'] & {
    getLayout?: (page: React.ReactNode) => React.ReactNode;
  };
}) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);
  const apolloClient = useApollo(pageProps.initialApolloState);

  const getLayout = Component.getLayout || ((page) => page);
  const titleTemplate = router.pathname.startsWith('/admin')
    ? `%s - Admin - ${SITE_NAME}`
    : `%s - ${SITE_NAME}`;

  const url = WEB_ROOT_URL + router.asPath;

  return (
    <IntlProvider locale="en">
      <ApolloProvider client={apolloClient}>
        <ToastProvider>
          <AuthModalProvider>
            <FirebaseAuthContextProvider>
              <FirebaseUserDocContextProvider>
                <CookiesProvider>
                  <Provider store={store as any}>
                    <AppSetup>
                      <>
                        <VersionCheck />
                        {process.env.NEXT_PUBLIC_INTERCOM_APP_ID && (
                          <Intercom
                            appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
                          />
                        )}
                        <AuthSetup store={store} />
                        <Toasts />
                        <DefaultSeo
                          titleTemplate={titleTemplate}
                          defaultTitle={SITE_NAME}
                          openGraph={{
                            type: 'website',
                            locale: LOCALE,
                            url,
                            site_name: SITE_NAME,
                            title: SITE_NAME,
                            images: [
                              {
                                url: 'https://atelie.art/preview_image.jpg',
                                width: 1000,
                                height: 750,
                                alt: 'Atelie app',
                              },
                            ],
                          }}
                        />
                        <Head>
                          <script src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver,Intl,Intl.RelativeTimeFormat,Intl.RelativeTimeFormat.~locale.nb" />
                          <meta
                            name="apple-itunes-app"
                            content="app-id=1219949845"
                          />
                          <meta
                            name="google-site-verification"
                            content="-kF0e8WnHW_fYIvKzLpagWyin8ds7-OglHGXb6IrAfI"
                          />
                          <meta name="theme-color" content="#16374A" />
                          <link
                            rel="apple-touch-icon"
                            sizes="180x180"
                            href="/apple-touch-icon.png"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href="/favicon-32x32.png"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            sizes="16x16"
                            href="/favicon-16x16.png"
                          />
                          <link rel="manifest" href="/site.webmanifest" />
                          <link
                            rel="mask-icon"
                            href="/safari-pinned-tab.svg"
                            color="#36a878"
                          />
                          <meta
                            name="msapplication-TileColor"
                            content="#da532c"
                          />
                          <meta name="theme-color" content="#ffffff" />

                          {/* <FacebookPixelScript /> */}
                          {FACEBOOK_DOMAIN_VERIFICATION && (
                            <meta
                              name="facebook-domain-verification"
                              content={FACEBOOK_DOMAIN_VERIFICATION}
                            />
                          )}
                        </Head>
                        {getLayout(<Component {...pageProps} err={err} />)}
                      </>
                    </AppSetup>
                  </Provider>
                </CookiesProvider>
              </FirebaseUserDocContextProvider>
            </FirebaseAuthContextProvider>
          </AuthModalProvider>
        </ToastProvider>
      </ApolloProvider>
    </IntlProvider>
  );
}
