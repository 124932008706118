import axios from 'axios';
import { ROOT_URL } from 'config';

export const instance = (options) => {
  const request = axios.create({
    baseURL: ROOT_URL,
    timeout: 20000,
    headers: { 'Content-Type': 'application/json' },
  });

  if (!options) {
    return request;
  }

  if (options.token) {
    request.defaults.headers['Authorization'] = `Bearer ${options.token}`;
  }

  return request;
};

const ROUTE_INVITE = 'HttpsInvite';
export const URL_ACCEPT_INVITATION = `${ROUTE_INVITE}/acceptInvitation`;

const ROUTE_CONVERSATIONS = 'HttpsConversations';
export const URL_START_CONVERSATION = `${ROUTE_CONVERSATIONS}/startConversation`;
export const URL_SEND_MESSAGE = `${ROUTE_CONVERSATIONS}/sendMessage`;

const ROUTE_CLOUD_MESSAGING = 'HttpsCloudMessaging';
export const URL_ADD_DEVICE_TO_USER = `${ROUTE_CLOUD_MESSAGING}/addDeviceToUser`;

const ROUTE_AUTH = 'HttpsAuth';
export const URL_DELETE_PROFILE = `${ROUTE_AUTH}/delete-profile`;

const ROUTE_FAVORITE = 'HttpsFavorite';
export const URL_CREATE_FAVORITE = `${ROUTE_FAVORITE}/create-favorite`;
export const URL_DELETE_FAVORITE = `${ROUTE_FAVORITE}/delete-favorite`;

const ROUTE_FOLLOWER = 'HttpsFollower';
export const URL_CREATE_FOLLOWER = `${ROUTE_FOLLOWER}/create-follower`;
export const URL_DELETE_FOLLOWER = `${ROUTE_FOLLOWER}/delete-follower`;

const ROUTE_NEWSLETTER = 'HttpsNewsletter';
export const URL_UPDATE_SUBSCRIBED = `${ROUTE_NEWSLETTER}/updateSubscription`;
export const URL_GET_SUBSCRIBED = `${ROUTE_NEWSLETTER}/subscribed`;

const ROUTE_ANON_NEWSLETTER = 'HttpsAnonNewsletter';
export const URL_CREATE_ANON_CONTACT = `${ROUTE_ANON_NEWSLETTER}/create`;

const ROUTE_DINTERO = 'HttpsDintero';
export const URL_CREATE_SESSION = `${ROUTE_DINTERO}/create-session`;
export const URL_CAPTURE_PAYMENT = `${ROUTE_DINTERO}/capture`;
export const URL_REFUND_PAYMENT = `${ROUTE_DINTERO}/refund`;
export const URL_CANCEL_PAYMENT = `${ROUTE_DINTERO}/void`;
export const URL_LIST_SALES = `${ROUTE_DINTERO}/list-sales`;

const ROUTE_ARTWORK = 'HttpsArtwork';
export const URL_ADD_ARTWORK = `${ROUTE_ARTWORK}/add-artwork`;
export const URL_DELETE_ARTWORK = `${ROUTE_ARTWORK}/delete-artwork`;
export const URL_UPDATE_ARTWORK = `${ROUTE_ARTWORK}/update-artwork`;
export const URL_ADD_ARTWORK_IMAGE = `${ROUTE_ARTWORK}/update-artwork/add-image`;
export const URL_DELETE_ARTWORK_IMAGE = `${ROUTE_ARTWORK}/update-artwork/delete-image`;

// Gen 2 Cloud URLS

const ROUTE_FAVORITES_V2 = 'HttpsFavoritesV2';
export const URL_CREATE_FAVORITE_V2 = `${ROUTE_FAVORITES_V2}/create-favorite`;
export const URL_DELETE_FAVORITE_V2 = `${ROUTE_FAVORITES_V2}/delete-favorite`;

const ROUTE_FOLLOWERS_V2 = 'HttpsFollowersV2';
export const URL_CREATE_FOLLOWERS_V2 = `${ROUTE_FOLLOWERS_V2}/create-follower`;
export const URL_DELETE_FOLLOWERS_V2 = `${ROUTE_FOLLOWERS_V2}/delete-follower`;

const ROUTE_MAP_V2 = 'HttpsMapV2';
export const URL_MAP_QUERY_V2 = `${ROUTE_MAP_V2}/query`;
