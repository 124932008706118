import React from 'react';
import { AtelierLink } from '@components/common/routing';
import { Anchor } from '@components/ui/Anchor';
import { Login } from './Login';
import { SignUp } from './SignUp';

type AuthType = 'login' | 'signup';

export function AuthUI({
  type,
  onSignupClick,
  onLoginClick,
}: {
  type: AuthType;
  onSignupClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  onLoginClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) {
  return (
    <div className="flex flex-col space-y-4 text-left">
      {type === 'login' && <Login onSignupClick={onSignupClick} />}
      {type === 'signup' && <SignUp onLoginClick={onLoginClick} />}
      <div className="text-sm text-gray-700">
        By {type === 'login' ? 'signing in' : 'signing up'} you accept
        Atelie&apos;s{' '}
        <AtelierLink href="/terms">
          <Anchor underline target="blank">
            terms
          </Anchor>
        </AtelierLink>
      </div>
    </div>
  );
}
