import React from 'react';
import classNames from 'classnames';
import { Info, X } from 'react-feather';

export function Note({
  type,
  fill,
  onDismissClick,
  children,
  className,
  ...props
}: {
  type?: 'error' | 'success' | 'warning' | 'info';
  fill?: boolean;
  onDismissClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>) {
  let Icon;
  switch (type) {
    case 'info': {
      Icon = Info;
      break;
    }
  }
  return (
    <div
      className={classNames(
        'px-4 py-3 flex rounded-md',
        {
          border: !fill,
          'border-red-300 text-red-400': type === 'error' && !fill,
          'bg-red-600': type === 'error' && fill,
          'border-green-300 text-green-400': type === 'success' && !fill,
          'bg-green-400': type === 'success' && fill,
          'border-orange-300 text-orange-400': type === 'warning' && !fill,
          'bg-orange-400': type === 'warning' && fill,
          'border-gray-400 text-gray-700': type === 'info' && !fill,
          'bg-gray-600 text-white': type === 'info' && fill,
          'text-white':
            (type === 'error' || type === 'success' || type === 'warning') &&
            fill,
        },
        className
      )}
      {...props}
    >
      {Icon && (
        <div className="flex-shrink-0 mr-2">
          <Icon aria-hidden="true" />
        </div>
      )}
      <div>{children}</div>
      {onDismissClick && (
        <div className="pl-3 ml-auto">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames('inline-flex p-2')}
              onClick={onDismissClick}
              aria-label="Lukk"
            >
              <X className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
