import { z } from 'zod';
import { FirebaseFirestoreTimestampSchema } from '../helpers';

export const userDocShema = z.object({
  photoUrl: z.string().optional(),
  servingPhotoUrl: z.string().optional(),
  stripeCustomerId: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  conversations: z.record(z.boolean()).optional(),
  isArtist: z.boolean().optional(),
  intercomId: z.string().optional(),
});

export const userPrivateDataDocShema = z.object({
  marketing: z.object({ email: z.boolean(), notification: z.boolean() }),
  privacyPolicy: z.object({
    gdpr: z.object({ accepted: z.boolean(), prompted: z.boolean() }),
  }),
  permalink: z.string(),
  createdAt: FirebaseFirestoreTimestampSchema,
  unreadConversations: z.array(z.string()),
});

export type UserDocShema = z.infer<typeof userDocShema>;
export type UserPrivateDataDocShema = z.infer<typeof userPrivateDataDocShema>;
