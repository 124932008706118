import React from 'react';
import classNames from 'classnames';

export const labelClassNames = 'block text-sm';

export const Label = ({
  className,
  id,
  ...props
}: React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>) => {
  return (
    <label
      htmlFor={id}
      className={classNames(labelClassNames, className)}
      {...props}
    />
  );
};
