import classNames from 'classnames';
import React from 'react';
import { ExternalLink } from 'react-feather';

interface AnchorProps {
  underline?: boolean;
  underlineHover?: boolean;
  external?: boolean;
  iconLeft?: React.ReactNode;
}

function getAnchorProps({
  underline,
  underlineHover,
  className,
  external,
  iconLeft,
  children,
}: AnchorProps & {
  className?: string;
  children?: React.ReactNode;
}) {
  return {
    className: classNames(className, 'cursor-pointer', {
      'underline hover:no-underline': underline,
      'hover:underline': underlineHover,
      'relative inline-flex items-center justify-center': iconLeft,
    }),
    children: (
      <>
        {iconLeft}
        {children}
        {external && (
          <ExternalLink className="inline ml-1" size={14} strokeWidth={1} />
        )}
      </>
    ),
  };
}

export const Anchor = React.forwardRef<
  HTMLAnchorElement,
  AnchorProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(
  (
    {
      className,
      external,
      underline,
      underlineHover,
      iconLeft,
      children,
      ...props
    },
    ref
  ) => (
    <a
      ref={ref}
      {...props}
      {...getAnchorProps({
        children,
        external,
        className,
        underline,
        iconLeft,
        underlineHover,
      })}
    />
  )
);

export const AnchorButton = React.forwardRef<
  HTMLButtonElement,
  AnchorProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      className,
      external,
      underline,
      underlineHover,
      iconLeft,
      children,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      {...props}
      {...getAnchorProps({
        children,
        external,
        className,
        underline,
        iconLeft,
        underlineHover,
      })}
    />
  )
);
