import { getStudioPathname, getArtworkPathname } from '@shared/routing-utils';
import Link, { LinkProps } from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import type { ParsedUrlQueryInput } from 'querystring';

// keep in sync with /pages
export type Pathname =
  | '/'
  | '/a/[slug]'
  | '/admin'
  | '/admin/orders'
  | '/admin/orders/[orderId]'
  | '/admin/products'
  | '/admin/products/create'
  | '/admin/products/[productId]'
  | '/studio/[permalinkOrId]'
  | '/studio/[permalinkOrId]/a/[artworkId]'
  | '/editions'
  | '/editions/[slug]'
  | '/editions-14'
  | '/editions-14/[slug]'
  | '/editions-15'
  | '/editions-15/[slug]'
  | '/kalender'
  | '/kalender/[slug]'
  | '/leie'
  | '/leie/[slug]'
  | '/editions-04'
  | '/editions-04/[slug]'
  | '/editions-aamodt'
  | '/editions-aamodt/[slug]'
  | '/editions-pigao'
  | '/editions-pigao/[slug]'
  | '/editions-barrios'
  | '/editions-barrios/[slug]'
  | '/editions-hvidsten'
  | '/editions-hvidsten/[slug]'
  | '/editions-terins'
  | '/editions-terins/[slug]'
  | '/editions-gellein'
  | '/editions-gellein/[slug]'
  | '/editions-mikkelsgard'
  | '/editions-mikkelsgard/[slug]'
  | '/editions-dahl'
  | '/editions-dahl/[slug]'
  | '/vika'
  | '/vika/[slug]'
  | '/i/[slug]'
  | '/k/[slug]'
  | '/giftcard'
  | '/map'
  | '/sign-up'
  | '/sign-in'
  | '/utleie'
  | '/terms'
  | '/privacy'
  | '/my-page'
  | '/my-page/payment-card'
  | '/my-page/my-purchases'
  | '/my-page/my-purchases/[orderId]'
  | '/my-page/messages'
  | '/my-page/messages/[conversationId]'
  | '/my-page/my-sales'
  | '/my-page/profile'
  | '/my-studio'
  | '/my-studio/artworks'
  | '/my-studio/artworks/add-artwork'
  | '/my-studio/artworks/edit/[artworkId]'
  | '/my-studio/exhibitions'
  | '/my-studio/exhibitions/add'
  | '/my-studio/analytics'
  | '/my-studio/exhibitions/[exhibitionId]/edit'
  | '/reset-password'
  | 'https://apps.apple.com/no/app/atelier/id1219949845'
  | 'studio/[permalinkOrId]'
  | '/studio/[permalinkOrId]/a/[artworkId]'
  | '/artists';

interface UrlObject {
  pathname: Pathname | null;
  query?: string | null | ParsedUrlQueryInput;
}

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
}

export type AtelierLinkHref = Pathname | UrlObject;

export interface AtelierLinkProps extends LinkProps {
  href: AtelierLinkHref;
}

export function AtelierLink({
  href,
  passHref = true,
  ...props
}: React.PropsWithChildren<AtelierLinkProps>) {
  return <Link href={href} passHref={passHref} {...props} />;
}

export function StudioLink({
  permalinkOrId,
  query,
  passHref = true,
  ...props
}: { permalinkOrId: string; query?: Record<string, string> } & Omit<
  React.PropsWithChildren<AtelierLinkProps>,
  'href'
>) {
  return (
    <Link
      href={{ pathname: getStudioPathname({ permalinkOrId }), query }}
      passHref={passHref}
      {...props}
    />
  );
}

export function ArtworkLink({
  id,
  studioPermalinkOrId,
  query,
  ...props
}: {
  id: string;
  studioPermalinkOrId: string;
  query?: Record<string, string>;
} & Omit<React.PropsWithChildren<AtelierLinkProps>, 'href'>) {
  return (
    <Link
      href={{
        pathname: getArtworkPathname({ id, studioPermalinkOrId }),
        query,
      }}
      {...props}
    />
  );
}

export function EditArtworkLink({
  id,
  query,
  passHref = true,
  ...props
}: {
  id: string;
  query?: Record<string, string>;
} & Omit<React.PropsWithChildren<AtelierLinkProps>, 'href'>) {
  return (
    <Link
      href={{ pathname: `/my-studio/artworks/edit/${id}`, query }}
      passHref={passHref}
      {...props}
    />
  );
}

export function ConversationLink({
  id,
  ...props
}: { id: string } & Omit<React.PropsWithChildren<AtelierLinkProps>, 'href'>) {
  return <Link href={`/my-page/messages/${id}`} {...props} />;
}

const logInPathname: Pathname = '/sign-in';
const createAccountPathname: Pathname = '/sign-up';
const resetPasswordPathname: Pathname = '/reset-password';

export function LogInLink(
  props: Omit<React.PropsWithChildren<AtelierLinkProps>, 'href'>
) {
  const router = useRouter();
  const returnTo = router.asPath;
  const query: { returnTo?: string } = {};
  if (
    returnTo &&
    returnTo !== logInPathname &&
    returnTo !== createAccountPathname
  ) {
    query.returnTo = returnTo;
  }
  return <AtelierLink href={{ pathname: logInPathname, query }} {...props} />;
}

export function CreateAccountLink(
  props: Omit<React.PropsWithChildren<AtelierLinkProps>, 'href'>
) {
  const router = useRouter();
  const returnTo = router.asPath;
  const query: { returnTo?: string } = {};
  if (
    returnTo &&
    returnTo !== createAccountPathname &&
    returnTo !== logInPathname &&
    returnTo !== resetPasswordPathname
  ) {
    query.returnTo = returnTo;
  }
  return (
    <AtelierLink href={{ pathname: createAccountPathname, query }} {...props} />
  );
}

export function useAtelierRouter(): {
  query: NextRouter['query'];
  pathname: Pathname;
  pushRoute: (href: AtelierLinkHref, options?: TransitionOptions) => void;
  replaceRoute: (href: AtelierLinkHref, options?: TransitionOptions) => void;
} {
  const router = useRouter();
  const pathname = router.pathname as Pathname;
  function pushRoute(href: AtelierLinkHref, options?: TransitionOptions) {
    router.push(href, undefined, options);
  }
  function replaceRoute(href: AtelierLinkHref, options?: TransitionOptions) {
    router.replace(href, undefined, options);
  }
  return { pathname, pushRoute, replaceRoute, query: router.query };
}
