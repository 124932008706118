import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import { FIREBASE_CONFIG } from './constants';
import { getConsented } from './analytics-consent';

const config = FIREBASE_CONFIG;

if (!config) {
  throw new Error('NEXT_PUBLIC_FIREBASE_CONFIG not set');
}

if (typeof window !== 'undefined') {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  // To enable analytics. https://firebase.google.com/docs/analytics/get-started
  if ('measurementId' in config) {
    const consented = getConsented();
    // disable tracking by default. is enabled again if the user have consented to tracking
    const disableGa = !consented;
    // @ts-expect-error ga-disable-measurementId is not typed
    window[`ga-disable-${config.measurementId}`] = disableGa;
    firebase.analytics();
  }
  if (location.hostname === 'localhost') {
    if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST) {
      firebase
        .auth()
        .useEmulator(
          `http://${process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST}`
        );
    }
    if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
      firebase.firestore().settings({
        host: process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST,
        ssl: false,
        experimentalAutoDetectLongPolling: true,
      });
    }
    if (process.env.NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST) {
      const [
        host,
        port,
      ] = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_EMULATOR_HOST.split(':');
      firebase.storage().useEmulator(host, parseInt(port));
    }
  }
}

export default firebase;
