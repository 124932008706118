import { Button } from '@components/ui/Button';
import { Input } from '@components/ui/Input';
import React, { useState, useEffect } from 'react';
import { AtelierLink, CreateAccountLink } from '@components/common/routing';
import { useForm } from 'react-hook-form';
import { SocialAuthButton } from '@components/auth/SocialAuthButton';
import { FirebaseAuthError } from '@components/auth/FirebaseAuthError';
import { useAuthUser } from '@lib/authentication';
import { useFirebaseAuthAccount } from './useFirebaseAuthAccount';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { Anchor } from '@components/ui/Anchor';
import { authMethods, getProviderDisplayName } from './utils';

interface FormValues {
  email: string;
  password: string;
}

function LoginEmailForm({
  disabled,
  loading,
  onSubmit,
  onCancelClick,
}: {
  disabled: boolean;
  loading: boolean;
  onSubmit: (values: FormValues) => void;
  onCancelClick: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form
      data-testid="login-form"
      className="space-y-4 text-left"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <Input
          label="Email"
          type="email"
          id="LoginEmailForm-email"
          autoFocus
          error={errors.email?.message}
          {...register('email', { required: true })}
        />
      </div>
      <div>
        <Input
          label="Password"
          type="password"
          id="LoginEmailForm-password"
          error={errors.password?.message}
          {...register('password', { required: true })}
        />
        <div className="mt-1 text-right">
          <AtelierLink href="/reset-password">
            <a className="text-sm underline hover:no-underline">
              Forgot password?
            </a>
          </AtelierLink>
        </div>
      </div>
      <div className="space-y-4">
        <Button
          type="submit"
          variant="primaryYellow"
          className="w-full"
          disabled={disabled}
          loading={loading}
        >
          Sign in
        </Button>
        <Button
          type="button"
          className="w-full"
          disabled={disabled || loading}
          onClick={() => {
            onCancelClick();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

export function Login({
  onSignupClick,
}: {
  onSignupClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) {
  const router = useRouter();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const { authenticated } = useAuthUser();
  const {
    signInWithEmailAndPassword,
    handleProviderAuthClick,
    firebaseAuthResult,
    createUserResult,
    loading,
    exisitingAccountUi,
  } = useFirebaseAuthAccount();
  useEffect(() => {
    if (
      !showEmailForm &&
      router.isReady &&
      router.query.email?.toString() === '1'
    ) {
      setShowEmailForm(true);
    }
  }, [showEmailForm, router.isReady, router.query]);
  return (
    <>
      {(firebaseAuthResult.error || createUserResult.error) && (
        <div>
          <FirebaseAuthError
            error={firebaseAuthResult.error}
            createUserError={createUserResult.error}
          />
        </div>
      )}
      <AnimatePresence initial={false} exitBeforeEnter>
        {!showEmailForm && (
          <motion.div
            key="login"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col space-y-2"
          >
            {authMethods.map((authMethod) => {
              return (
                <SocialAuthButton
                  key={authMethod}
                  method={authMethod}
                  disabled={loading || authenticated}
                  loading={firebaseAuthResult.type === authMethod && loading}
                  onClick={async () => {
                    if (authMethod === 'password') {
                      setShowEmailForm(true);
                    } else {
                      handleProviderAuthClick(authMethod, 'signin');
                    }
                  }}
                >
                  Sign in with {getProviderDisplayName(authMethod)}
                </SocialAuthButton>
              );
            })}
          </motion.div>
        )}
        {exisitingAccountUi}
        {showEmailForm && (
          <motion.div
            key="email"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoginEmailForm
              loading={firebaseAuthResult.type === 'password' && loading}
              disabled={loading || authenticated}
              onSubmit={async (values) => {
                await signInWithEmailAndPassword(values.email, values.password);
              }}
              onCancelClick={() => {
                setShowEmailForm(false);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div>
        Don&apos;t have an account?{' '}
        <CreateAccountLink>
          <Anchor underline onClick={onSignupClick}>
            Sign up
          </Anchor>
        </CreateAccountLink>
      </div>
    </>
  );
}
