import firebase from '@lib/firebase';

export const usersCollection = () => firebase.firestore().collection('users');
export const usersPrivateDataDoc = (userId: string) =>
  usersCollection().doc(userId).collection('private-data').doc(userId);
export const userStripePaymentMethodsCollection = (userId: string) =>
  usersCollection().doc(userId).collection('stripe-payment-methods');
export const userStripeSubscriptionsCollection = (userId: string) =>
  usersCollection().doc(userId).collection('stripe-subscriptions');
export const userStripeCheckoutSessionsCollection = (userId: string) =>
  usersCollection().doc(userId).collection('stripe-checkout-sessions');
