import { authChanged, logout } from 'actions/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { userRole, getRole } from './roles';
import firebase from './firebase';
import * as Sentry from '@sentry/nextjs';
import { setUserId } from './analytics';
import {
  UserDocShema,
  userDocShema,
  UserPrivateDataDocShema,
} from './client/models/user';
import {
  usersCollection,
  usersPrivateDataDoc,
} from './client/controllers/users';
import { z } from 'zod';

export interface FirebaseAuthContextInterface {
  loading: boolean;
  authenticated: boolean;
  user: firebase.User | null;
  error: firebase.auth.Error | undefined;
}

export const FirebaseAuthContext = createContext<FirebaseAuthContextInterface>({
  loading: true,
  authenticated: false,
  user: null,
  error: undefined,
});

/**
 * This will provide "user" through a hook.
 * Anything that needs to be handled before the hook user should be handled here
 * @param param0
 * @returns
 */
export function FirebaseAuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loading, setLoadingUser] = useState(true);
  const [error, setError] = useState<firebase.auth.Error | undefined>();

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.uid, email: user.email || undefined });
      setUserId(user.uid);
    } else {
      Sentry.setUser(null);
      setUserId(null);
    }
  }, [user]);

  useEffect(() => {
    const auth = firebase.auth();

    const unsubscriber = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // const userDoc = await firebase.firestore().doc(`users/${uid}`).get()
          setUser(user);
        } else {
          setUser(null);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoadingUser(false);
      }
    });

    return () => unsubscriber();
  }, []);

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        loading,
        error,
        authenticated: Boolean(user),
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
}

export const useAuthUser = () => useContext(FirebaseAuthContext);

export const useUserRole = () => {
  const userAuthResult = useAuthUser();
  const [role, setRole] = useState<userRole | undefined>();
  const [loading, setLoading] = useState(userAuthResult.loading);
  const [error, setError] = useState<Error | undefined>();
  useEffect(() => {
    async function getClaims() {
      if (!userAuthResult.user) {
        return;
      }
      setLoading(true);
      setError(undefined);
      try {
        const idToken = await userAuthResult.user.getIdTokenResult();
        const role = getRole(idToken.claims);
        if (role) {
          setRole(role);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    getClaims();
  }, [userAuthResult.user]);

  return { role, loading, error };
};

export const useAdminRoleCheck = () => {
  const { role } = useUserRole();
  return role === userRole.admin;
};

const userSchema = userDocShema.extend({
  id: z.string(),
  fullName: z.string(),
  intercomId: z.string().optional(),
});

export type UserSchema = z.infer<typeof userSchema>;

export interface FirebaseUserDocContextInterface {
  loading: boolean;
  userDoc: UserSchema | null;
  error?: Error | undefined;
}

export const FirebaseUserDocContext =
  createContext<FirebaseUserDocContextInterface>({
    loading: true,
    userDoc: null,
  });

export const FirebaseUserDocContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuthUser();
  const uid = user ? user.uid : undefined;
  const [userDoc, loading, error] = useUserDocById(uid);
  return (
    <FirebaseUserDocContext.Provider
      value={{
        userDoc: userDoc || null,
        loading,
        error,
      }}
    >
      {children}
    </FirebaseUserDocContext.Provider>
  );
};

export function useUserDocById(uid: string | undefined) {
  return useDocumentData<UserSchema>(uid ? usersCollection().doc(uid) : null, {
    transform: (user: UserDocShema) => {
      return {
        ...user,
        id: uid || '',
        fullName: `${user.firstName} ${user.lastName}`.trim(),
        photoUrl: user.photoUrl, // user.servingPhotoUrl || // Commented out becaouse deleteServingUrl does not work
      };
    },
  });
}

export const useUserDoc = () => useContext(FirebaseUserDocContext);

export const usePrivateData = () => {
  const { user } = useAuthUser();
  return useDocumentData<UserPrivateDataDocShema>(
    user && user.uid ? usersPrivateDataDoc(user.uid) : null
  );
};

export function AuthSetup({ store }: { store: any }) {
  const { userDoc, loading: loadingUserDoc } = useUserDoc();
  const { user, loading: loadingUser } = useAuthUser();
  useEffect(() => {
    if (loadingUser || loadingUserDoc) {
      return;
    }
    if (!user) {
      logout(store);
    }
    if (user && userDoc) {
      authChanged(store, user, userDoc);
    }
  }, [loadingUser, loadingUserDoc, userDoc, user]);
  return null;
}
