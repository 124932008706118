import { MAP_SET_ZOOM, MAP_SELECT_MARKER, MAP_CLOSE_MARKER } from 'actions/map';

const initialState = {
  zoom: 7,
  clusterZoom: 15,
  selectedMarker: null,
  defaultZoom: 7,
};

export default function Map(state = initialState, action) {
  switch (action.type) {
    case MAP_SET_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    case MAP_SELECT_MARKER: {
      return { ...state, selectedMarker: action.payload };
    }
    case MAP_CLOSE_MARKER: {
      return { ...state, selectedMarker: null };
    }
    default:
      return state;
  }
}
