import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT_TRANSACTIONS,
  CAPTURE_PAYMENT,
  VOID_PAYMENT,
} from 'actions/products';

const initialState = {
  products: {
    isLoading: true,
    data: [],
  },
  transactions: {
    isLoading: true,
    data: [],
  },
  command: {
    isComplete: false,
    error: '',
  },
};
export default function Products(state = initialState, action) {
  switch (action.type) {
    case CAPTURE_PAYMENT: {
      return { ...state, command: action.payload };
    }
    case VOID_PAYMENT: {
      return { ...state, command: action.payload };
    }
    case FETCH_PRODUCTS: {
      return { ...state, products: action.payload };
    }
    case FETCH_PRODUCT_TRANSACTIONS: {
      return { ...state, transactions: action.payload };
    }
    default:
      return state;
  }
}
