export function getArtworkPathname({
  id,
  studioPermalinkOrId,
}: {
  id: string;
  studioPermalinkOrId: string;
}) {
  return `/studio/${studioPermalinkOrId}/a/${id}`;
}

export function getStudioPathname({
  permalinkOrId,
}: {
  permalinkOrId: string;
}) {
  return `/studio/${permalinkOrId}`;
}
