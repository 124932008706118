import { nanoid } from 'nanoid';

export function randomString(size = 8) {
  return nanoid(size);
}

export function createImageId() {
  return `img_${nanoid()}`;
}

export function createOrderId() {
  return `or_${nanoid()}`;
}
