// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Debug as DebugIntegration } from '@sentry/integrations';
import { APP_ENV } from './lib/constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const integrations = [];

if (APP_ENV === 'development') {
  integrations.push(
    new DebugIntegration({
      // trigger DevTools debugger instead of using console.log
      debugger: false,
      // stringify event before passing it to console.log
      stringify: false,
    })
  );
}

Sentry.init({
  environment: APP_ENV,
  dsn:
    SENTRY_DSN ||
    'https://c6d5769777364842b400b208a2ea572a@o504603.ingest.sentry.io/5591716',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: ['Network Error'],
  integrations,
});
