
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApplyGiftCardCodeResponse": [
      "ApplyGiftCardCodeError",
      "CurrentUser"
    ],
    "ArtistInviteResponse": [
      "ArtistInvite",
      "InvalidArtistInvite"
    ],
    "ArtworkOrderPayment": [
      "ArtworkOrderPaymentGiftCard",
      "ArtworkOrderPaymentStripePaymentIntent"
    ],
    "Conversation": [
      "ConversationItem",
      "ConversationListItem"
    ],
    "Event": [
      "AtelierEvent",
      "Exhibition"
    ],
    "Image": [
      "ImageUnsized",
      "ImageWithRatio",
      "ImageWithSize"
    ],
    "Notification": [
      "ReleaseNote"
    ],
    "Order": [
      "ArtworkOrder",
      "GiftCardOrder",
      "PaymentOrder",
      "SubscriptionOrder"
    ],
    "OrderLine": [
      "OrderLineArtwork",
      "OrderLineEditions",
      "OrderLineFrame",
      "OrderLineGiftCardCoupon"
    ],
    "Product": [
      "ProductEditions",
      "ProductFrame"
    ],
    "Studio": [
      "CurrentStudio",
      "StudioDetails",
      "StudioListItem"
    ]
  }
};
      export default result;
    