import { combineReducers } from 'redux';
import authReducer from './auth';
import inviteReducer from './invite';
import studioReducer from './studio';
import mapReducer from './map';
import favoritesReducer from './favorites';
import productReducer from './products';

import atelierEvents from './atelierEvents';
import eventsReducer from './events';

import { AUTH_LOGOUT } from '../actions/auth';

const makeRootReducer = (asyncReducers) => {
  const appReducer = combineReducers({
    studio: studioReducer,
    auth: authReducer,
    invite: inviteReducer,
    map: mapReducer,
    ...asyncReducers,
    favorites: favoritesReducer,
    products: productReducer,

    atelierEvents,
    events: eventsReducer,
  });

  return (state, action) => {
    if (action.type === AUTH_LOGOUT) {
      /* logout will be called each refresh if user is not auth. This keeps site state. */
      state = {};
    }

    return appReducer(state, action);
  };
};

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
