import { gql } from '@apollo/client';
import { useCurrentUserQuery } from '@generated/codegen';
import { useAuthUser } from '@lib/authentication';

export const STUDIO_ADDRESS_FRAGMENT = gql`
  fragment StudioAddress on Address {
    location {
      lat
      lng
    }
    name
    city
    country
    municipality
    postCode
    region
    street
    streetNumber
    place_id
  }
`;

export const MY_STUDIO_FRAGMENT = gql`
  ${STUDIO_ADDRESS_FRAGMENT}
  fragment MyStudio on CurrentStudio {
    id
    name
    about
    techniques
    permalinkOrId
    atelierURL
    address {
      ...StudioAddress
    }
    stripeAccount {
      id
      charges_enabled
      details_submitted
    }
    followersCount
    webUrl
    instagramUrl
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${MY_STUDIO_FRAGMENT}
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      isArtist
      photoURL
      studio {
        ...MyStudio
      }
    }
  }
`;

export function useCurrentUser(
  options?: Omit<Parameters<typeof useCurrentUserQuery>[0], 'ssr' | 'skip'>
) {
  const { user } = useAuthUser();
  const { data, ...result } = useCurrentUserQuery({
    ssr: false,
    skip: !user,
    ...options,
  });
  return { ...result, currentUser: data && data.currentUser };
}
