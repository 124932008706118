import firebase from 'firebase/app';
const { firestore } = firebase;

export const MAP_SET_ZOOM = 'MAP_SET_ZOOM';
export const MAP_SELECT_MARKER = 'MAP_SELECT_MARKER';
export const MAP_CLOSE_MARKER = 'MAP_CLOSE_MARKER';

export const FETCH_MARKERS = 'fetch-markers';
export const FETCH_USER = 'fetch-user';

export const selectMarker = (id) => {
  return (dispatch, getState) => {
    const { map } = getState();

    if (map.selectedMarker === id) {
      dispatch({
        type: MAP_CLOSE_MARKER,
      });
    } else {
      dispatch({
        type: MAP_SELECT_MARKER,
        payload: id,
      });
    }
  };
};

export const closeMarker = (id) => {
  return {
    type: MAP_CLOSE_MARKER,
  };
};

export const setZoom = (zoom) => {
  return {
    type: MAP_SET_ZOOM,
    payload: zoom,
  };
};

export function fetchMarkers() {
  return (dispatch) => {
    return firestore()
      .collection('studios')
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_MARKERS,
            payload: {
              isLoading: false,
              data: [],
            },
          });
          return;
        }

        const markers = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }

          const marker = Object.assign(
            doc.data(),
            { key: doc.id },
            { isOpen: false }
          );
          markers.push(marker);
        });

        dispatch({
          type: FETCH_MARKERS,
          payload: {
            isLoading: false,
            data: markers,
          },
        });
      });
  };
}

export function fetchUsername(studioId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER,
    });

    getUsername(studioId).then((ownerName) => {
      console.log('action fetching user');
      dispatch({
        type: FETCH_USER,
        payload: {
          ownerName,
        },
      });
    });
  };
}

const getUsername = (studioId) => {
  console.log('get username ');
  return firestore()
    .collection('users')
    .doc(studioId)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return Promise.resolve('Namn ikkje satt');
      }

      const user = doc.data();
      const name = `${user.firstName} ${user.lastName}`;

      return Promise.resolve(name);
    });
};
