import type { ApolloError } from '@apollo/client';
import { AtelierLink, CreateAccountLink } from '@components/common/routing';
import { Anchor } from '@components/ui/Anchor';
import firebase from '@lib/firebase';
import React from 'react';

export const FirebaseAuthError = ({
  error,
  createUserError,
}: {
  error: firebase.auth.Error | null;
  createUserError: ApolloError | undefined;
}) => {
  let heading: string | undefined;
  let message: string | React.ReactNode | undefined;
  if (error) {
    switch (error.code) {
      case 'auth/popup-closed-by-user': {
        return null;
      }
      case 'auth/email-already-in-use': {
        heading = 'Email taken';
        message = 'This email address is already in use.';
        break;
      }
      case 'auth/user-not-found': {
        heading = 'User not found';
        message = (
          <>
            Could not find a user with this email address. Would you like to{' '}
            <CreateAccountLink>
              <Anchor underline>sign up</Anchor>
            </CreateAccountLink>
            ?
          </>
        );
        break;
      }
      case 'email-spam': {
        heading = 'Calm down!';
        message = 'Too many attempts, please wait for an hour and try again.';
        break;
      }
      case 'auth/wrong-password': {
        heading = 'Wrong password';
        message = (
          <>
            Would you like to{' '}
            <AtelierLink href="/reset-password">
              <Anchor underline>reset your password</Anchor>
            </AtelierLink>
            ?
          </>
        );
        break;
      }
      case 'auth/account-exists-with-different-credential': {
        heading = 'Email already registered.';
        message = 'An account with this email already exists.';
        break;
      }
      default: {
        heading = 'Something went wrong';
        message = error.message;
      }
    }
  } else if (createUserError) {
    heading = 'Something went wrong';
    message = createUserError.message;
  }

  if (!heading) {
    return null;
  }

  return (
    <div className="p-4 text-red-900 bg-red-50">
      {heading && <div className="font-bold">{heading}</div>}
      <div>{message}</div>
    </div>
  );
};
