import { z } from 'zod';
import slugify from '@sindresorhus/slugify';
import firebase from '@lib/firebase';

export const FirebaseFirestoreTimestampSchema = z.instanceof(
  firebase.firestore.Timestamp
);

export const idSchema = z.object({
  id: z.string(),
});

export const slugSchema = z
  .string()
  .nonempty('Enter a URL slug')
  .max(255)
  .refine(
    (val) => val === slugify(val),
    (val) => ({
      message: `Enter a valid URL slug, eg "${slugify(val)}"`,
    })
  );
