import { useAuthUser } from '@lib/authentication';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export function Intercom({ appId }: { appId: string }) {
  const { user } = useAuthUser();
  const router = useRouter();

  useEffect(() => {
    if (!window.Intercom) {
      return;
    }
    window.Intercom('boot', {
      app_id: appId,
    });
  }, [appId]);

  useEffect(() => {
    if (!window.Intercom) {
      return;
    }

    if (user) {
      // Adding hacky timeout to let firebase event create user in Intercom first
      setTimeout(() => {
        // This will init the user session
        window.Intercom('boot', {
          app_id: appId,
          user_id: user.uid,
          email: user.email ?? undefined,
        });
      }, 2000);
    }
  }, [user]);

  useEffect(() => {
    const handleRouteChange = () => {
      window.Intercom('update');
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
        }}
      />
    </Head>
  );
}

/**
 * Hide Intercom launcher on mount, and show it on unmount
 */
export function useHideIntercom() {
  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
    return () => {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    };
  }, []);
}
