import React, { ElementType } from 'react';
import { Dialog } from '@headlessui/react';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Button, ButtonProps } from '@components/ui/Button';
import { AnimatePresence, motion } from 'framer-motion';
import { Check, X } from 'react-feather';
import classNames from 'classnames';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: { y: { duration: 0.25 } },
  },
  closed: {
    y: -100,
    opacity: 0,
    transition: { y: { duration: 0.6 } },
  },
  switching: {
    y: -50,
    opacity: 0,
    transition: { y: { duration: 0.2 } },
  },
};

export interface ModalProps {
  open: boolean;
  size?: 'md' | 'lg';
  /**
   * Use this to switch the modal content window without fading the overlay
   */
  switching?: boolean;
  children: React.ReactNode;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  onClose: () => void;
  state?: 'success' | 'error' | 'loading';
  onAnimationComplete?: (definition: keyof typeof variants) => void;
  disableExitAnimation?: boolean;
  'data-testid'?: string;
  hideCloseButton?: boolean;
  className?: string;
}

function ModalHeader({
  title,
  showCloseButton,
  onClose,
  headerActions,
}: {
  title: React.ReactNode;
  showCloseButton: boolean;
  onClose: () => void;
  headerActions: React.ReactNode;
}) {
  return (
    <div className="flex items-center">
      {title && (
        <div
          className={classNames('flex-1 py-4 truncate', {
            'text-left': showCloseButton,
          })}
        >
          {title}
        </div>
      )}
      {showCloseButton && (
        <div>
          <button
            className="flex items-center py-4"
            aria-label="Close"
            onClick={onClose}
          >
            <X size={24} strokeWidth={1} /> Close
          </button>
        </div>
      )}
      {headerActions}
    </div>
  );
}

function isTitle(child: React.ReactNode) {
  return (
    React.isValidElement(child) &&
    typeof child.type === 'function' &&
    child.type.name === Title.name
  );
}

function isHeaderActions(child: React.ReactNode) {
  return (
    React.isValidElement(child) &&
    typeof child.type === 'function' &&
    child.type.name === HeaderActions.name
  );
}

export function Modal({
  open,
  size = 'md',
  onClose,
  initialFocus,
  children,
  state,
  switching,
  onAnimationComplete,
  disableExitAnimation,
  hideCloseButton,
  ...props
}: ModalProps) {
  const loading = state === 'loading';
  const error = state === 'error';
  const success = state === 'success';
  let animate = open ? 'open' : 'closed';
  if (switching) {
    animate = 'switching';
  }
  let modalTitle: React.ReactNode = null;
  let headerActions: React.ReactNode = null;
  React.Children.forEach(children, (child) => {
    if (isTitle(child)) {
      modalTitle = child;
    } else if (isHeaderActions(child)) {
      headerActions = child;
    }
  });
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          as={motion.div}
          static
          open={open}
          className="fixed inset-0 z-40 overflow-y-auto"
          initial="closed"
          animate={animate}
          exit={disableExitAnimation ? undefined : 'closed'}
          onClose={onClose}
          initialFocus={initialFocus}
          data-testid={props['data-testid']}
        >
          <div
            className={classNames('min-h-screen text-center', {
              'px-4': size === 'md',
            })}
          >
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Dialog.Overlay
              as={motion.div}
              className="fixed inset-0 bg-gray-500"
              variants={{
                open: { opacity: 0.75 },
                closed: { opacity: 0 },
                switching: { opacity: 0.75 },
              }}
            />
            <motion.div
              tabIndex={1}
              className={classNames(
                // remember to keep the x paddings here in sync with Breakout
                'relative w-full px-4 pb-6 md:px-6 inline-block align-middle bg-white shadow-xl',
                {
                  'max-w-md my-8 overflow-hidden': size === 'md',
                  'min-h-screen md:min-h-0 overflow-y-auto md:max-w-screen-lg lg:my-8':
                    size === 'lg',
                },
                props.className
              )}
              variants={variants}
              onAnimationComplete={onAnimationComplete}
            >
              <ModalHeader
                title={modalTitle}
                onClose={onClose}
                showCloseButton={!loading && !hideCloseButton}
                headerActions={headerActions}
              />
              {state && (
                <div
                  className={classNames(
                    {
                      'bg-green-100': !loading && !error,
                      'bg-gray-100': loading && !error,
                      'bg-red-100': error,
                    },
                    'mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4'
                  )}
                >
                  {error && (
                    <X className="w-6 h-6 text-red-600" aria-hidden="true" />
                  )}
                  {loading && <LoadingSpinner />}
                  {success && (
                    <Check
                      className="w-6 h-6 text-green-600"
                      aria-hidden="true"
                    />
                  )}
                </div>
              )}
              {state !== 'loading' && (
                <>
                  {React.Children.map(children, (child) => {
                    if (isTitle(child) || isHeaderActions(child)) {
                      return null;
                    }
                    return child;
                  })}
                </>
              )}
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
}

/**
 * This is the title for your Dialog. When this is used, it will set the aria-labelledby on the Dialog.
 *
 * {@link https://headlessui.dev/react/dialog#dialog-title|Read more}
 */
function Title({
  srOnly,
  textCenter,
  ...props
}: { srOnly?: boolean; textCenter?: boolean } & Omit<
  Parameters<typeof Dialog.Title>[0],
  'className'
>) {
  return (
    <Dialog.Title
      className={classNames(
        'text-lg overflow-ellipsis overflow-hidden',
        { 'sr-only': srOnly },
        { 'text-center': textCenter }
      )}
      {...props}
    />
  );
}

/**
 * This is the description for your Dialog. When this is used, it will set the aria-describedby on the Dialog.
 *
 * {@link https://headlessui.dev/react/dialog#dialog-description|Read more}
 */
function Description({
  children,
  as,
}: {
  children: React.ReactNode;
  as?: ElementType<any>;
}) {
  return <Dialog.Description as={as}>{children}</Dialog.Description>;
}

function Actions({ children }: { children: React.ReactNode }) {
  return <div className="flex justify-center mt-4 space-x-4">{children}</div>;
}

function Action(
  props: {
    children: React.ReactNode;
  } & Pick<ButtonProps, 'variant' | 'loading'> &
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'type' | 'disabled'
    >
) {
  return <Button {...props} />;
}

function Body({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

function Form(props: React.FormHTMLAttributes<HTMLFormElement>) {
  return <form {...props} />;
}

function Breakout({ children }: { children: React.ReactNode }) {
  return <div className="-mx-4 -md:mx-6 lg:mx-0">{children}</div>;
}

function HeaderActions({ children }: { children: React.ReactNode }) {
  return <div className="flex-1">{children}</div>;
}

Modal.Form = Form;
Modal.Body = Body;
Modal.Title = Title;
Modal.Description = Description;
Modal.Actions = Actions;
Modal.Action = Action;
Modal.HeaderActions = HeaderActions;
Modal.Breakout = Breakout;
