import React, { isValidElement } from 'react';
import classNames from 'classnames';
import { Label } from './Label';
import { InlineError } from './InlineError';
import { Tooltip } from './Tooltip';
import { HelpCircle } from 'react-feather';

const iconEndClassName = classNames(
  'absolute right-2 inset-y-0 flex items-center text-muted'
);

const iconEndSize = 'h-6 w-6';

function IconEnd({ children }: { children: React.ReactNode }) {
  return (
    <div className={iconEndClassName}>
      {isValidElement(children)
        ? React.cloneElement(children, {
            className: classNames(children.props.className, iconEndSize),
          })
        : children}
    </div>
  );
}

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'size'
> & {
  id: string;
  label?: string;
  wrapperClassName?: string;
  error?: string | false;
  currency?: string;
  /**
   * Use numeric for number values.
   * Ref https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
   */
  type?: 'numeric' | 'numeric-float' | string;
  /** Use border variant when there are few input elements on the same page, otherwise use light variant */
  variant?: InputVariant;
  description?: React.ReactNode;
  rounded?: 'full';
  size?: 'sm';
} & (
    | {
        /**
         * Shows help icon placed on end of input. Can not be used together with iconEnd
         */
        helpTooltip?: React.ReactNode;
        iconEnd?: never;
      }
    | {
        helpTooltip?: never;
        iconEnd?: React.ReactNode;
      }
  );

export type InputVariant = 'bordered' | 'light';

export function getInputClassNames({
  variant,
  error,
  readOnly,
  rounded,
  size,
}: Pick<InputProps, 'variant' | 'error' | 'readOnly' | 'rounded' | 'size'>) {
  return classNames(
    'appearance-none w-full focus:border-gray-900 outline-none disabled:opacity-60',
    { border: variant === 'bordered' },
    { 'py-2': !size && variant === 'bordered' },
    { 'py-0.5 ': size === 'sm' && variant === 'bordered' },
    { 'py-2 px-0': !size && variant === 'light' },
    { 'py-1 px-0': size === 'sm' && variant === 'light' },
    { 'border-red': error },
    { 'border-gray-400': !error },
    { 'border-b focus:outline-none': variant === 'light' },
    { 'opacity-60': readOnly },
    {
      'rounded-full px-4': rounded === 'full' && variant === 'bordered',
    },
    { 'rounded-md': !rounded && variant === 'bordered' },
    { 'px-3': !rounded && variant === 'bordered' && !size },
    { 'px-1.5': !rounded && variant === 'bordered' && size === 'sm' }
  );
}

export function InputDescription({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div className={classNames('text-muted text-sm', className)} {...props} />
  );
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      wrapperClassName,
      id,
      label,
      iconEnd,
      error,
      variant = 'bordered',
      type,
      description,
      helpTooltip,
      readOnly,
      rounded,
      size,
      ...props
    },
    ref
  ) => {
    const numeric = type === 'numeric';
    const numericFloat = type === 'numeric-float';
    const descriptionId = `${id}-description`;
    const haveIconEnd = Boolean(iconEnd || helpTooltip);
    let pattern: string | undefined;
    if (numeric) {
      pattern = '[0-9]*';
    } else if (numericFloat) {
      pattern = '[0-9]+([,.][0-9]+)?';
    }
    return (
      <div className={wrapperClassName}>
        {label && (
          <Label
            htmlFor={id}
            className={classNames({
              'mb-2': variant === 'bordered',
            })}
          >
            {label}
          </Label>
        )}
        <div className="relative flex">
          <input
            id={id}
            ref={ref}
            type={numeric || numericFloat ? 'text' : type}
            inputMode={numeric || numericFloat ? 'numeric' : undefined}
            // this is a workaround to avoid zooming when focusing on iOS.
            // remove when `body { font-size: 14px }` is removed from src/styles/semantic.min.css
            style={{ fontSize: 16 }}
            className={classNames(
              className,
              getInputClassNames({ variant, error, readOnly, rounded, size }),
              { 'pr-10': haveIconEnd }
            )}
            readOnly={readOnly}
            aria-describedby={description ? `${id}-description` : undefined}
            {...props}
            pattern={props.pattern || pattern}
          />
          {iconEnd && <IconEnd>{iconEnd}</IconEnd>}
          {helpTooltip && (
            <Tooltip
              trigger="mouseenter click"
              hideOnClick={true}
              render={helpTooltip}
            >
              <button aria-label="Informasjon" className={iconEndClassName}>
                <HelpCircle strokeWidth={1} className={iconEndSize} />
              </button>
            </Tooltip>
          )}
        </div>
        {error && <InlineError className="mt-2">{error}</InlineError>}
        {description && (
          <InputDescription id={descriptionId} className="mt-2">
            {description}
          </InputDescription>
        )}
      </div>
    );
  }
);
