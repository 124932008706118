import firebase from 'firebase/app';
const { firestore } = firebase;

export const LOADING_ATELIER_EVENTS = 'LOADING_ATELIER_EVENTS';
export const GET_ATELIER_EVENTS = 'GET_ATELIER_EVENTS';
export const ATELIER_EVENTS_RESET = 'ATELIER_EVENTS_RESET';

let unsubscriber;
export const getAtelierEvents = () => {
  if (unsubscriber) {
    unsubscriber();
  }

  return (dispatch) => {
    dispatch({
      type: LOADING_ATELIER_EVENTS,
    });

    unsubscriber = firestore()
      .collection('atelier-events')
      .onSnapshot((query) => {
        if (query.empty) {
          return dispatch({
            type: GET_ATELIER_EVENTS,
            payload: [],
          });
        }

        const docs = query.docs.filter((doc) => doc.exists);
        if (!docs || !docs.length) {
          return dispatch({
            type: GET_ATELIER_EVENTS,
            payload: [],
          });
        }

        const ae = docs.map((doc) => doc.data());

        dispatch({
          type: GET_ATELIER_EVENTS,
          payload: ae,
        });
      });
  };
};
