import React, { useEffect, useState } from 'react';
import { useAuthUser } from '@lib/authentication';
import { LogInLink } from '@components/common/routing';
import { Checkbox } from '@components/ui/Checkbox';
import { useRouter } from 'next/router';
import { SocialAuthButton } from '@components/auth/SocialAuthButton';
import { SignUpEmailForm } from '@components/auth/SignUpEmailForm';
import { FirebaseAuthError } from '@components/auth/FirebaseAuthError';
import { useFirebaseAuthAccount } from '@components/auth/useFirebaseAuthAccount';
import { gql } from '@apollo/client';
import { useArtistInviteByCodeQuery } from '@generated/codegen';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Check, X } from 'react-feather';
import classNames from 'classnames';
import { Anchor } from '@components/ui/Anchor';
import { AnimatePresence, motion } from 'framer-motion';
import { authMethods, getProviderDisplayName } from './utils';

gql`
  query artistInviteByCode($code: String!) {
    artistInviteByCode(code: $code) {
      ... on ArtistInvite {
        email
      }
      ... on InvalidArtistInvite {
        errorCode
        message
      }
    }
  }
`;

function ArtistInviteByCode({ code }: { code: string }) {
  const { data, loading, error } = useArtistInviteByCodeQuery({
    variables: { code },
  });
  return (
    <div className="mb-4">
      <div
        className={classNames(
          'flex items-center justify-center w-12 h-12 mx-auto mb-2 rounded-full',
          {
            'bg-gray-100': loading,
            'bg-green-100':
              data?.artistInviteByCode.__typename === 'ArtistInvite',
            'bg-red-100':
              data?.artistInviteByCode.__typename === 'InvalidArtistInvite' ||
              error,
          }
        )}
      >
        {loading && <LoadingSpinner />}
        {data && (
          <>
            {data.artistInviteByCode.__typename === 'ArtistInvite' && <Check />}
            {data.artistInviteByCode.__typename === 'InvalidArtistInvite' && (
              <X />
            )}
          </>
        )}
        {error && <X />}
      </div>
      <div className="text-center">
        {error && <div>Failed to load invitation</div>}
        {data && (
          <>
            {data.artistInviteByCode.__typename === 'InvalidArtistInvite' &&
              data.artistInviteByCode.message}
            {data.artistInviteByCode.__typename === 'ArtistInvite' && (
              <>
                <div className="mb-2">Valid invitation</div>
                <div>
                  Create an account and we will create an artist profile for you
                  where you can upload and sell your artwork.
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function SignUp({
  onLoginClick,
}: {
  onLoginClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailMarketing, setEmailMarketing] = useState(false);
  const { authenticated } = useAuthUser();
  const router = useRouter();
  const {
    createUserWithEmailAndPassword,
    handleProviderAuthClick,
    firebaseAuthResult,
    createUserResult,
    loading,
    exisitingAccountUi,
  } = useFirebaseAuthAccount();
  const code = router.query.code?.toString();
  const artistByInviteCodeResult = useArtistInviteByCodeQuery({
    skip: !code,
    variables: { code: code || '' },
  });
  const invitedEmail =
    artistByInviteCodeResult.data?.artistInviteByCode.__typename ===
    'ArtistInvite'
      ? artistByInviteCodeResult.data.artistInviteByCode.email
      : undefined;
  useEffect(() => {
    if (router.isReady && router.query.email?.toString() === '1') {
      setShowEmailForm(true);
    }
  }, [router.isReady, router.query]);

  return (
    <>
      {code && <ArtistInviteByCode code={code} />}
      <AnimatePresence initial={false} exitBeforeEnter>
        {!showEmailForm && (
          <motion.div
            key="login"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col space-y-2"
          >
            {authMethods.map((authMethod) => {
              return (
                <SocialAuthButton
                  key={authMethod + '_' + authMethods.indexOf(authMethod)}
                  method={authMethod}
                  disabled={
                    loading || authenticated || artistByInviteCodeResult.loading
                  }
                  loading={firebaseAuthResult.type === authMethod && loading}
                  onClick={async () => {
                    if (authMethod === 'password') {
                      setShowEmailForm(true);
                    } else {
                      handleProviderAuthClick(
                        authMethod,
                        'createUser',
                        emailMarketing
                      );
                    }
                  }}
                >
                  Sign up with {getProviderDisplayName(authMethod)}
                </SocialAuthButton>
              );
            })}
          </motion.div>
        )}
        {(firebaseAuthResult.error || createUserResult.error) && (
          <FirebaseAuthError
            error={firebaseAuthResult.error}
            createUserError={createUserResult.error}
          />
        )}
        {showEmailForm && (
          <motion.div
            key="password"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SignUpEmailForm
              invitedEmail={invitedEmail}
              loading={firebaseAuthResult.type === 'password' && loading}
              onSubmit={async (values) => {
                if (invitedEmail) {
                  values.email = invitedEmail;
                }

                await createUserWithEmailAndPassword({
                  ...values,
                  emailMarketing,
                });
              }}
              onCancelClick={() => {
                setShowEmailForm(false);
              }}
            />
          </motion.div>
        )}
        {exisitingAccountUi}

        <div className="text-sm text-gray-700">
          <Checkbox
            checked={emailMarketing}
            onChange={(event) => {
              const { checked } = event.target;
              setEmailMarketing(checked);
            }}
          >
            I would like to receive emails from Atelie with art recommendations,
            news, and new releases.
          </Checkbox>
        </div>
      </AnimatePresence>

      {!exisitingAccountUi && (
        <div>
          Already have an account?{' '}
          <LogInLink>
            <Anchor underline onClick={onLoginClick}>
              Sign in
            </Anchor>
          </LogInLink>
        </div>
      )}
    </>
  );
}
