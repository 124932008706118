import React from 'react';
import classNames from 'classnames';

export function CheckboxRadio({
  variant,
  input,
  className,
  children,
  type,
  removable = true,
  ...props
}: {
  type: 'checkbox' | 'radio';
  variant?: 'tag';
  input: React.ReactNode;
  removable?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>) {
  if (!React.isValidElement(input)) {
    return null;
  }
  const clonedInput = React.cloneElement(input, {
    className: classNames(input.props.className, 'peer z-10', {
      'text-yellow w-3 h-3 mr-1': variant === 'tag',
      'text-white': !variant,
    }),
  });
  return (
    <label
      className={classNames('inline-flex items-center relative', className, {
        'mr-2 text-sm tracking-wider': variant === 'tag',
      })}
      {...props}
    >
      {clonedInput}
      <span
        className={classNames('z-10 select-none', {
          '': variant === 'tag',
          'peer-checked:mr-0 mr-4': variant === 'tag' && type === 'checkbox',
          'ml-0.5': variant === 'tag' && !removable,
          'ml-1': !variant,
        })}
      >
        {children}
      </span>
      {variant === 'tag' && (
        <span
          className="absolute z-0 -mr-0.5 rounded-full -inset-x-2 -inset-y-1 peer-checked:bg-yellow"
          aria-hidden={true}
        />
      )}
    </label>
  );
}
