import { STRIPE_DASHBOARD_URL } from '@lib/constants';

const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);

export const projectId = firebaseConfig.projectId;

export const credentials = firebaseConfig;

export const algolia = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
};

export const intercom = {
  appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
};
export const googleMap = {
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
};

export const stripe = {
  clientId: process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID,
  publishablekey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  dashboardUrl: STRIPE_DASHBOARD_URL,
};

export const ROOT_URL = process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_HOST
  ? `http://${process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_HOST}/${projectId}/us-central1`
  : `https://us-central1-${projectId}.cloudfunctions.net`;
