export const AUTH_CHANGED = 'auth-changed'
export const AUTH_LOGOUT = 'auth-logout'

export const logout = store => {
  return store.dispatch({
    type: AUTH_LOGOUT
  })
}

export const authChanged = (store, user, profile) => {
  return store.dispatch({
    type: AUTH_CHANGED,
    payload: {
      user, 
      profile
    }
  })
}