import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import { Button } from '@components/ui/Button';
import { X } from 'react-feather';

interface Toast {
  id: string;
  text: React.ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error';
  closable?: boolean;
  /**
   * Time to live. Set to 0 to persist it until closed
   */
  ttl: number;
  action?: {
    text: React.ReactNode;
    onClick: (
      id: string,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
  };
}

interface ToastInput extends Omit<Toast, 'id' | 'ttl'> {
  id?: string;
  ttl?: number;
}

type Action =
  | { type: 'ADD_TOAST'; payload: Toast }
  | { type: 'REMOVE_TOAST'; payload: string };

const initialState: Toast[] = [];

function toastReducer(state: Toast[], action: Action) {
  switch (action.type) {
    case 'ADD_TOAST': {
      if (state.some((toast) => toast.id === action.payload.id)) {
        return state;
      }
      return [...state, action.payload];
    }
    case 'REMOVE_TOAST': {
      return state.filter((toast) => action.payload !== toast.id);
    }
    default:
      return state;
  }
}

export const ToastContext = createContext<
  [Toast[], React.Dispatch<Action>] | undefined
>(undefined);

export const ToastProvider = (props: { children: React.ReactNode }) => {
  const reducer = useReducer(toastReducer, initialState);
  return (
    <ToastContext.Provider value={reducer}>
      {props.children}
    </ToastContext.Provider>
  );
};

export function useToasts(): {
  toasts: Toast[];
  addToast: (toast: ToastInput) => void;
  removeToast: (id: string) => void;
} {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToasts must be used within a ToastProvider');
  }
  const [toasts, dispatch] = context;

  /** Displays a toast until ttl (time to live) expires */
  function addToast({
    id = Math.random().toString(),
    ttl = 3000,
    ...toast
  }: ToastInput) {
    dispatch({
      type: 'ADD_TOAST',
      payload: { id, ttl, ...toast },
    });
  }

  /** Remove a toast by its id */
  function removeToast(id: string) {
    dispatch({
      type: 'REMOVE_TOAST',
      payload: id,
    });
  }

  return { toasts, addToast, removeToast };
}

const Toast = ({
  toast,
  dispatch,
}: {
  toast: Toast;
  dispatch: React.Dispatch<Action>;
}) => {
  const { id, ttl, action, type, closable } = toast;
  useEffect(() => {
    if (!ttl) {
      return;
    }
    const timeoutId = setTimeout(() => {
      dispatch({ type: 'REMOVE_TOAST', payload: id });
    }, ttl);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [id, ttl, dispatch]);
  return (
    <div
      className={classNames(
        'flex w-full flex-row bg-white p-3 mb-3 md:max-w-md lg:max-w-lg space-x-2',
        {
          'bg-white shadow': !type,
          'bg-green-300': type === 'success',
          'bg-red-300 text-white': type === 'error',
          'bg-yellow-300': type === 'warning',
          'bg-blue-200 text-blue-900': type === 'info',
        }
      )}
    >
      <div className="flex-1">{toast.text}</div>
      {action && (
        <div className="ml-2">
          <Button
            onClick={(event) => {
              action.onClick(id, event);
            }}
            variant="primaryYellow"
            size="sm"
          >
            {action.text}
          </Button>
        </div>
      )}
      {closable && (
        <div>
          <button
            className="block"
            type="button"
            onClick={() => {
              dispatch({ type: 'REMOVE_TOAST', payload: id });
            }}
            aria-label="Remove"
          >
            <X size={24} />
          </button>
        </div>
      )}
    </div>
  );
};

export const Toasts = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('Toasts must be used within a ToastProvider');
  }
  const [toasts, dispatch] = context;
  return (
    <div
      className="fixed top-0 left-0 z-50 w-full md:w-auto md:left-2/4 md:transform md:-translate-x-2/4 md:mt-2"
      data-testid="Toasts"
    >
      <AnimatePresence initial={true}>
        {toasts.map((toast) => {
          return (
            <motion.div
              key={toast.id}
              layout="position"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50, transition: { duration: 0.2 } }}
              className="w-full md:w-auto"
            >
              <Toast dispatch={dispatch} toast={toast} />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};
