import firebase from 'firebase/app';
const { firestore } = firebase;

export const FETCH_FAVORITES = 'fetch-favorites';
export const FETCH_FOLLOWING = 'fetch-following';

export const fetchFavorites = (uid) => {
  return (dispatch) => {
    return firestore()
      .collection('artworks')
      .where(`favorites.${uid}`, '>', new Date(0))
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_FAVORITES,
            payload: {
              isLoading: false,
              artworks: [],
            },
          });
          return;
        }

        const artworks = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }
          const data = Object.assign(doc.data(), { key: doc.id });
          artworks.push(data);
        });

        dispatch({
          type: FETCH_FAVORITES,
          payload: {
            isLoading: false,
            artworks: artworks,
          },
        });
      });
  };
};

export const fetchFollowing = (uid) => {
  return (dispatch) => {
    return firestore()
      .collection('studios')
      .where(`followers.${uid}`, '>', new Date(0))
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_FOLLOWING,
            payload: {
              isLoading: false,
              artists: [],
            },
          });
          return;
        }

        const artists = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }
          const data = Object.assign(doc.data(), { key: doc.id });
          artists.push(data);
        });

        dispatch({
          type: FETCH_FOLLOWING,
          payload: {
            isLoading: false,
            artists: artists,
          },
        });
      });
  };
};
