import firebase from 'firebase/app';
const { firestore, auth } = firebase;

export const FETCH_INVITE = 'fetch-invite';
export const ACCEPT_INVITE = 'accept-invite';
export const SET_PROMPTED = 'set-prompted';

let unsubscribe;

export function fetchInvite() {
  if (unsubscribe) {
    unsubscribe();
  }

  const uid = auth().currentUser.uid;

  return (dispatch) => {
    unsubscribe = firestore()
      .collection('users')
      .doc(uid)
      .collection('invite')
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_INVITE,
            payload: { hasInvite: false },
          });
          return;
        }

        const invite = query.docs[0].data();
        invite.artistInviteRef.get().then((artistInviteDoc) => {
          if (!artistInviteDoc.exists) {
            dispatch({
              type: FETCH_INVITE,
              payload: { hasInvite: false },
            });
            return;
          }

          const artistInvite = artistInviteDoc.data();

          artistInvite.fromRef.get().then((userDoc) => {
            if (!userDoc.exists) {
              console.error('has invite, but user is deleted');
              dispatch({
                type: FETCH_INVITE,
                payload: {
                  from: {
                    uid: artistInvite.fromId,
                    name: `Anonym`,
                  },
                  hasInvite: true,
                  accepted: invite.accepted,
                  acceptedAt: artistInvite.acceptedAt,
                  createdAt: artistInvite.createdAt,
                  key: artistInviteDoc.id,
                  prompted: invite.prompted,
                },
              });
              return;
            }

            const user = userDoc.data();

            dispatch({
              type: FETCH_INVITE,
              payload: {
                from: {
                  uid: artistInvite.fromId,
                  name: `${user.firstName} ${user.lastName}`,
                },
                hasInvite: true,
                accepted: invite.accepted,
                acceptedAt: artistInvite.acceptedAt,
                createdAt: artistInvite.createdAt,
                key: artistInviteDoc.id,
                prompted: invite.prompted,
              },
            });
          });
        });
      });
  };
}

export const setPrompted = () => {
  const uid = auth().currentUser.uid;
  return (dispatch) => {
    firestore()
      .collection('users')
      .doc(uid)
      .collection('invite')
      .doc(uid)
      .update({
        prompted: true,
      });
  };
};
