import {
  GET_ATELIER_EVENTS,
  LOADING_ATELIER_EVENTS,
} from 'actions/atelierEvents';

const initialState = {
  isLoading: true,
  events: [],
};
export default function AtelierEvents(state = initialState, action) {
  switch (action.type) {
    case GET_ATELIER_EVENTS: {
      return { isLoading: false, events: action.payload };
    }
    case LOADING_ATELIER_EVENTS: {
      return initialState;
    }
    default:
      return state;
  }
}
