import firebase from 'firebase/app';
const { firestore, auth } = firebase;
import {
  instance,
  URL_LIST_SALES,
  URL_CAPTURE_PAYMENT,
  URL_CANCEL_PAYMENT,
  URL_REFUND_PAYMENT,
} from 'constants/https';

export const FETCH_PRODUCTS = 'fetch-products';
export const FETCH_PRODUCT_TRANSACTIONS = 'FETCH_PRODUCT_TRANSACTIONS';
export const CAPTURE_PAYMENT = 'CAPTURE_PAYMENT';
export const VOID_PAYMENT = 'VOID_PAYMENT';

export const refundPayment = (transactionId, amount) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRODUCT_TRANSACTIONS,
      payload: {
        isLoading: true,
        data: [],
      },
    });

    return auth()
      .currentUser.getIdToken()
      .then((token) => {
        return instance({ token })
          .post(URL_REFUND_PAYMENT, { transactionId, amount })
          .then((response) => {
            dispatch(fetchProductTransactions());
          })
          .catch((error) => {
            dispatch(fetchProductTransactions());
            console.log(error);
          });
      });
  };
};

export const capturePayment = (transactionId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRODUCT_TRANSACTIONS,
      payload: {
        isLoading: true,
        data: [],
      },
    });

    return auth()
      .currentUser.getIdToken()
      .then((token) => {
        return instance({ token })
          .post(URL_CAPTURE_PAYMENT, { transactionId })
          .then((response) => {
            dispatch(fetchProductTransactions());
            dispatch({
              type: CAPTURE_PAYMENT,
              payload: {
                isComplete: true,
                error: '',
              },
            });
          })
          .catch((error) => {
            dispatch(fetchProductTransactions());
            dispatch({
              type: CAPTURE_PAYMENT,
              payload: {
                isComplete: true,
                error:
                  'En feil oppstod ved godkjenning av betaling, dette kan forekomme hvis betalingen allerde er godkjent eller avist. Sjekk om du har fått e-post som bekrefter godkjenning.',
              },
            });
            console.log(error);
          });
      });
  };
};

export const cancelPayment = (transactionId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRODUCT_TRANSACTIONS,
      payload: {
        isLoading: true,
        data: [],
      },
    });

    return auth()
      .currentUser.getIdToken()
      .then((token) => {
        return instance({ token })
          .post(URL_CANCEL_PAYMENT, { transactionId })
          .then((response) => {
            dispatch(fetchProductTransactions());
            dispatch({
              type: VOID_PAYMENT,
              payload: {
                isComplete: true,
                error: '',
              },
            });
          })
          .catch((error) => {
            dispatch(fetchProductTransactions());
            dispatch({
              type: VOID_PAYMENT,
              payload: {
                isComplete: true,
                error:
                  'En feil oppstod ved avising av betaling, dette kan forekomme hvis betalingen allerde er godkjent eller avist. Sjekk om du har fått e-post som bekrefter avising.',
              },
            });
            console.log(error);
          });
      });
  };
};

export const fetchProductTransactions = () => {
  return (dispatch) => {
    return auth()
      .currentUser.getIdToken()
      .then((token) => {
        return instance({ token })
          .get(URL_LIST_SALES)
          .then((response) => {
            dispatch({
              type: FETCH_PRODUCT_TRANSACTIONS,
              payload: {
                isLoading: false,
                data: response.data,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: FETCH_PRODUCT_TRANSACTIONS,
              payload: {
                isLoading: false,
                data: [],
              },
            });
            console.log(error);
          });
      });
  };
};

export const fetchProducts = (uid) => {
  return (dispatch) => {
    return firestore()
      .collection('payments')
      .where('sellerId', '==', uid)
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_PRODUCTS,
            payload: {
              isLoading: false,
              data: [],
            },
          });
          return;
        }

        const products = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }
          const data = Object.assign(doc.data(), { key: doc.id });
          products.push(data);
        });

        Promise.all(
          products.map((product) => addBuyerName(product.buyerId, product))
        ).then((p) => {
          dispatch({
            type: FETCH_PRODUCTS,
            payload: {
              isLoading: false,
              data: p,
            },
          });
        });
      });
  };
};

const addBuyerName = (id, p) => {
  return firestore()
    .collection('users')
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { ...p, buyerIsDeleted: true };
      }

      const data = doc.data();
      const name = `${data.firstName} ${data.lastName}`;

      return {
        ...p,
        buyerName: name,
        buyerIsArtist: data.isArtist,
        buyerIsDeleted: false,
      };
    });
};
