import { Button } from '@components/ui/Button';
import { Input } from '@components/ui/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateUserSchema, createUserSchema } from '@lib/schemas/user';
import React, { useEffect } from 'react';
import { Lock } from 'react-feather';
import { useForm } from 'react-hook-form';

export function SignUpEmailForm({
  loading,
  invitedEmail,
  onSubmit,
  onCancelClick,
}: {
  loading: boolean;
  invitedEmail?: string;
  onSubmit: (values: CreateUserSchema) => void;
  onCancelClick: () => void;
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: invitedEmail || '',
      password: '',
    },
    resolver: zodResolver(createUserSchema),
  });
  useEffect(() => {
    if (invitedEmail) {
      setValue('email', invitedEmail);
    }
  }, [invitedEmail]);
  const emailReadOnly = Boolean(invitedEmail);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <Input
        id="firstName"
        label="First name"
        error={errors.firstName?.message}
        autoFocus
        {...register('firstName')}
      />
      <Input
        id="lastName"
        label="Last name"
        error={errors.lastName?.message}
        {...register('lastName')}
      />
      <Input
        id="email"
        label="Email"
        error={errors.email?.message}
        type="email"
        readOnly={emailReadOnly}
        iconEnd={emailReadOnly && <Lock strokeWidth={1} />}
        {...register('email')}
      />
      <Input
        id="password"
        label="Password"
        error={errors.password?.message}
        type="password"
        {...register('password')}
      />
      <div className="space-y-4">
        <Button
          type="submit"
          variant="primaryYellow"
          className="w-full"
          loading={loading}
        >
          Sign up
        </Button>
        <Button
          type="button"
          className="w-full"
          disabled={loading}
          onClick={() => {
            onCancelClick();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
