import classNames from 'classnames';
import React, { HTMLAttributes, DetailedHTMLProps } from 'react';
import { AlertCircle } from 'react-feather';

type InlineErrorProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const InlineError: React.FC<InlineErrorProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(className, 'text-red-400 flex text-sm leading-4')}
      {...props}
    >
      <AlertCircle className="mr-1" size={18} />
      <div style={{ marginTop: '2px' }}>{children}</div>
    </div>
  );
};
