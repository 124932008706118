import firebase from '@lib/firebase';
import orderBy from 'lodash/orderBy';

export const RESET_STUDIO = 'reset-studio';
export const FETCH_STUDIO = 'fetch-studio';
export const FETCH_EXHIBITIONS = 'fetch-exhibitions';
export const FETCH_FOLLOWERS = 'fetch-followers';
export const FETCH_EVENTS = 'FETCH_EVENTS';

const { firestore } = firebase;

let ExhibitionsSubscriber;
export const fetchExhibitions = (studioId) => {
  if (ExhibitionsSubscriber) {
    ExhibitionsSubscriber();
  }

  return (dispatch) => {
    ExhibitionsSubscriber = firestore()
      .collection('exhibitions')
      .where('studioId', '==', studioId)
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_EXHIBITIONS,
            payload: {
              isLoading: false,
              data: [],
            },
          });
          return;
        }

        let exhibitions = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }
          const data = Object.assign(doc.data(), { key: doc.id });
          exhibitions.push(data);
        });

        exhibitions = orderBy(
          exhibitions,
          (exhibition) => exhibition.from,
          'desc'
        );

        dispatch({
          type: FETCH_EXHIBITIONS,
          payload: {
            isLoading: false,
            data: exhibitions,
          },
        });
      });
  };
};

let eventsSubscriber;
export const fetchEvents = (studioId) => {
  if (eventsSubscriber) {
    eventsSubscriber();
  }

  return (dispatch) => {
    eventsSubscriber = firestore()
      .collection('events')
      .where('studioId', '==', studioId)
      .onSnapshot((query) => {
        if (query.empty) {
          dispatch({
            type: FETCH_EVENTS,
            payload: {
              isLoading: false,
              data: [],
            },
          });
          return;
        }

        let events = [];
        query.forEach((doc) => {
          if (!doc.exists) {
            return false;
          }
          const data = Object.assign(doc.data(), { key: doc.id });
          events.push(data);
        });

        events = orderBy(events, (event) => event.from, 'desc');

        dispatch({
          type: FETCH_EVENTS,
          payload: {
            isLoading: false,
            data: events,
          },
        });
      });
  };
};
