import React from 'react';
import { AuthMethod } from './utils';
import { Button, ButtonProps, ButtonVariant } from '@components/ui/Button';

interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Pick<ButtonProps, 'size' | 'children' | 'loading'> {
  disabled: boolean;
  className?: string;
  onClick?: () => void;
  method: AuthMethod;
}

export function SocialAuthButton({
  loading,
  children,
  method,
  ...props
}: Props) {
  let variant: ButtonVariant = 'outline';
  switch (method) {
    case 'password': {
      variant = 'outline';
      break;
    }
    case 'apple.com': {
      variant = 'black';
      break;
    }
    case 'facebook.com': {
      variant = 'facebook';
      break;
    }
    case 'google.com': {
      variant = 'google';
      break;
    }
  }
  return (
    <Button loading={loading} variant={variant} {...props}>
      {children}
    </Button>
  );
}
