import { FETCH_INVITE, ACCEPT_INVITE, SET_PROMPTED } from 'actions/invite'

const initialState = {
  invite: null
}
export default function Invite(state = initialState, action) {
  switch(action.type) {
    case FETCH_INVITE: { return action.payload }
    case ACCEPT_INVITE: { return { ...state, ...action.payload };}
    case SET_PROMPTED: { return { ...state, ...action.payload };}
    default: return state;
  }
}
