import type { ClientConfig } from 'next-sanity';

export const SITE_NAME = 'Atelie';
export const LEGAL_NAME = 'Atelie AS';
export const CONTACT_EMAIL = 'hello@atelier.as';
export const WEB_ROOT_URL = process.env.NEXT_PUBLIC_BASE_URL as string;

export enum CURRENCY {
  NOK = 'NOK',
  EUR = 'EUR',
}
export const LOCALE = 'no';
export const STRIPE_LOCALE = 'en';

if (!process.env.NEXT_PUBLIC_SANITY_PROJECT_ID) {
  throw new Error('process.env.NEXT_PUBLIC_SANITY_PROJECT_ID not set');
}

if (!process.env.NEXT_PUBLIC_SANITY_DATASET) {
  throw new Error('process.env.NEXT_PUBLIC_SANITY_DATASET not set');
}

export const SANITY_CONFIG: ClientConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: true,
  apiVersion: 'v1',
};

export const APP_ENV =
  (process.env.NEXT_PUBLIC_VERCEL_ENV as
    | 'development'
    | 'preview'
    | 'production'
    | undefined) || 'development';

export const STRIPE_DASHBOARD_URL = `https://dashboard.stripe.com${
  process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? '/test' : ''
}`;

export const FACEBOOK_PIXEL_ID =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? '289868011714778'
    : '123';

export const FACEBOOK_DOMAIN_VERIFICATION =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? '4jwujngbnl8a9p4jy0nxtc83o0zok8'
    : '';

export const FEATURE_FLAGS = {
  artwork_rental_enabled: process.env.NODE_ENV === 'development',
};

if (!process.env.NEXT_PUBLIC_ALGOLIA_APP_ID) {
  throw new Error('process.env.NEXT_PUBLIC_ALGOLIA_APP_ID not defined');
}
if (!process.env.NEXT_PUBLIC_ALGOLIA_API_KEY) {
  throw new Error('process.env.NEXT_PUBLIC_ALGOLIA_API_KEY not defined');
}

export const ALGOLIA_CONFIG = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
};

export const IS_SERVER = typeof window === 'undefined';

export const ARTWORK_MODAL_QUERY_KEY = 'artworkModal';

export const FIREBASE_CONFIG: { projectId: string } | undefined = process.env
  .NEXT_PUBLIC_FIREBASE_CONFIG
  ? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG)
  : undefined;

export const FUNCTIONS_ROOT_URL = process.env
  .NEXT_PUBLIC_FIREBASE_FUNCTIONS_HOST
  ? `http://${process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_HOST}/${FIREBASE_CONFIG?.projectId}/us-central1`
  : `https://us-central1-${FIREBASE_CONFIG?.projectId}.cloudfunctions.net`;

export const ANALYTICS_CONSENT_COOKIE_NAME = 'cookie-policy';
