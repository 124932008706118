import classNames from 'classnames';
import React from 'react';
import { CheckboxRadio } from './CheckboxRadio';

export interface CheckboxProps {
  checked?: boolean;
  children?: React.ReactNode;
  variant?: 'tag';
  color?: 'green' | 'orange' | 'blue';
  removable?: boolean;
}

export const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & CheckboxProps
>(
  (
    { className, variant, color, children, removable = true, ...props },
    ref
  ) => {
    return (
      <CheckboxRadio
        type="checkbox"
        variant={variant}
        className={className}
        removable={removable}
        input={
          <input
            ref={ref}
            type="checkbox"
            className={classNames('form-checkbox', {
              'text-yellow': !variant,
              'text-green': color === 'green',
              'text-orange': color === 'orange',
              'text-blue': color === 'blue',
              'form-checkbox-tag checked:border-transparent hover:checked:border-transparent focus:checked:border-transparent text-yellow':
                variant === 'tag',
              'hidden checked:block': removable && variant === 'tag',
              hidden: !removable,
            })}
            {...props}
          />
        }
      >
        {children}
      </CheckboxRadio>
    );
  }
);
