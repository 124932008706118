import firebase from 'firebase';

// This is needed to be able to test the signInWithPopup error codes in Cypress, as the emulator does not support all error codes
// ref https://github.com/firebase/firebase-tools/issues/3518
declare global {
  interface Window {
    signInWithPopup: (
      auth: firebase.auth.Auth,
      provider: firebase.auth.AuthProvider
    ) => Promise<firebase.auth.UserCredential>;
  }
}

async function windowSignInWithPopup(
  auth: firebase.auth.Auth,
  provider: firebase.auth.AuthProvider
) {
  return auth.signInWithPopup(provider);
}

if (typeof window !== 'undefined') {
  window.signInWithPopup = windowSignInWithPopup;
}

export function signInWithPopup(
  auth: firebase.auth.Auth,
  provider: firebase.auth.AuthProvider
) {
  return window.signInWithPopup(auth, provider);
}
