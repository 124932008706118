import { AUTH_LOGOUT, AUTH_CHANGED } from '../actions/auth';

const initialState = {
  isInitializing: true,
  authenticated: false,
  user: null,
  profile: null,
};
export default function Auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGOUT: {
      return {
        isInitializing: false,
        authenticated: false,
        user: null,
        profile: null,
      };
    }
    case AUTH_CHANGED: {
      return {
        isInitializing: false,
        authenticated: true,
        user: action.payload.user,
        profile: action.payload.profile,
      };
    }
    default:
      return state;
  }
}
