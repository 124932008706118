import { z } from 'zod';

export const createUserSchema = z.object({
  email: z.string().email('Skriv inn en gyldig e-post'),
  password: z
    .string()
    .min(6, 'Skriv inn minst 6 tegn')
    .nonempty('Skriv inn et passord'),
  firstName: z.string().nonempty('Skriv inn ditt fornavn'),
  lastName: z.string().nonempty('Skriv inn ditt etternavn'),
});

export type CreateUserSchema = z.infer<typeof createUserSchema>;
