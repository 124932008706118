import {
  FETCH_STUDIO,
  RESET_STUDIO,
  FETCH_EXHIBITIONS,
  FETCH_FOLLOWERS,
  FETCH_EVENTS,
} from 'actions/studio';

const initialState = {
  isLoading: true,
  doesExists: false,
  artworks: {
    isLoading: true,
  },
  exhibitions: {
    isLoading: true,
  },
  events: {
    isLoading: true,
  },
  followers: {
    isLoading: true,
  },
};
export default function Studio(state = initialState, action) {
  switch (action.type) {
    case RESET_STUDIO:
      return initialState;
    case FETCH_STUDIO: {
      return { ...state, ...action.payload };
    }
    case FETCH_EXHIBITIONS: {
      return { ...state, exhibitions: { ...action.payload } };
    }
    case FETCH_EVENTS: {
      return { ...state, events: { ...action.payload } };
    } // new exhibitions
    case FETCH_FOLLOWERS: {
      return { ...state, followers: action.payload };
    }
    default:
      return state;
  }
}
