import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import introspection from '../../generated/possible-types';
import firebase from '@lib/firebase';
import {
  TypedTypePolicies,
  PaginatedArtworks,
  ArtworkImage,
} from '@generated/codegen';
import * as Sentry from '@sentry/nextjs';
import { sha256 } from 'crypto-hash';
import { randomString } from '@shared/nanoid';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export async function getAuthorizationHeader(user: firebase.User) {
  const token = await user.getIdToken();
  return { authorization: `Bearer ${token}` };
}

const authLink = setContext((_, { headers }) => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return;
  }

  return getAuthorizationHeader(currentUser).then(({ authorization }) => {
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      const context: Parameters<typeof Sentry.captureException>[1] = {};
      context.extra = {};
      if (extensions) {
        context.extra = extensions;
      }
      if (
        message.includes('FAILED_PRECONDITION: The query requires an index')
      ) {
        const matches = message.match(/\bhttps?:\/\/\S+/gi);
        if (matches && matches[0]) {
          context.extra.url = matches[0];
        }
      }
      return Sentry.captureMessage(message, context);
    });
  }
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

const transactionIdLink = new ApolloLink((operation, forward) => {
  // https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry#1-generate-a-unique-identifier-and-set-as-a-sentry-tag-on-issuing-service
  const transactionId = randomString(8);
  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', transactionId);
  });
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Transaction-ID': transactionId,
    },
  }));
  return forward(operation);
});

function createIsomorphLink() {
  if (typeof window === 'undefined') {
    const { SchemaLink } = require('@apollo/client/link/schema');
    const { schema } = require('./schema');
    const { createContext } = require('./context');
    return ApolloLink.from([
      transactionIdLink,
      errorLink,
      new SchemaLink({ schema, context: createContext }),
    ]);
  } else {
    const { HttpLink } = require('@apollo/client/link/http');
    return ApolloLink.from([
      transactionIdLink,
      authLink,
      errorLink,
      createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
      new HttpLink({
        uri: '/api/graphql',
        credentials: 'same-origin',
      }),
    ]);
  }
}

const typePolicies: TypedTypePolicies = {
  Artwork: {
    fields: {
      images: {
        merge(
          _existing: ArtworkImage[] | undefined = [],
          incoming: ArtworkImage[]
        ) {
          return incoming;
        },
      },
    },
  },
  CurrentStudio: {
    fields: {
      artworks: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Query: {
    fields: {
      artwork: (_, { args, toReference }) => {
        if (!args) {
          return;
        }
        return toReference({
          __typename: 'Artwork',
          id: args.id,
        });
      },
      artworks: {
        keyArgs: [
          'query',
          'index',
          'minPrice',
          'maxPrice',
          'techniques',
          'facets',
          'studioIds',
        ],
        merge(
          existing: PaginatedArtworks | undefined,
          incoming: PaginatedArtworks,
          { args }
        ): PaginatedArtworks {
          const merged =
            existing && existing.hits ? existing.hits.slice(0) : [];
          if (args) {
            // Assume an page of 0 if args.page omitted.
            const { page = 0, perPage = 0 } = args;
            const offset = page * perPage;
            for (let i = 0; i < incoming.hits.length; ++i) {
              merged[offset + i] = incoming.hits[i];
            }
          }
          return {
            nbHits: existing ? existing.nbHits : incoming.nbHits,
            haveMorePages: incoming.haveMorePages,
            hits: merged,
            hasMoreHits: Boolean(incoming.hasMoreHits || existing?.hasMoreHits),
            facets: incoming.facets || existing?.facets || null,
          };
        },
      },
    },
  },
};

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createIsomorphLink(),
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
      typePolicies,
    }),
    name: 'atelier-web',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null
) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
