import React, { isValidElement } from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from './LoadingSpinner';
import { motion } from 'framer-motion';

function IconLeft({
  children,
  size,
}: {
  children: React.ReactNode;
  size: ButtonProps['size'];
}) {
  if (!isValidElement(children)) {
    return null;
  }
  return React.cloneElement(children, {
    className: classNames(
      children.props.className,
      { 'h-4 w-4 mr-1': size === 'sm' },
      { 'h-6 w-6 mr-2': !size }
    ),
  });
}

function IconEnd({
  children,
  size,
}: {
  children: React.ReactNode;
  size: ButtonProps['size'];
}) {
  if (!isValidElement(children)) {
    return null;
  }
  return React.cloneElement(children, {
    className: classNames(
      children.props.className,
      { 'h-4 w-4 mr-1': size === 'sm' },
      { 'h-6 w-6 mr-2': !size }
    ),
  });
}

export type ButtonVariant =
  | 'primaryYellow'
  | 'primaryGreen'
  | 'primaryGray'
  | 'outline'
  | 'outlineGreen'
  | 'link'
  | 'danger'
  | 'black'
  | 'facebook'
  | 'google';

export type ButtonProps = {
  size?: 'sm';
  variant?: ButtonVariant;
  loading?: boolean;
  iconLeft?: React.ReactNode;
  iconEnd?: React.ReactNode;
  children?: React.ReactNode;
};

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      className,
      type = 'button',
      variant,
      size,
      disabled,
      children,
      loading,
      iconLeft,
      iconEnd,
      ...props
    },
    ref
  ) => {
    const buttonProps = useButtonProps({
      className,
      variant,
      size,
      disabled,
      children,
      loading,
      iconLeft,
      iconEnd,
    });
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        {...buttonProps}
        {...props}
      />
    );
  }
);

export function useButtonProps({
  className,
  variant = 'outline',
  disabled,
  loading,
  size,
  iconLeft,
  iconEnd,
  children,
}: {
  className?: string;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  loading?: boolean;
  size?: ButtonProps['size'];
  iconLeft?: ButtonProps['iconLeft'];
  iconEnd?: ButtonProps['iconEnd'];
  children?: React.ReactNode;
}): { className: string; children: React.ReactNode } {
  return {
    className: classNames(
      className,
      'relative border text-center rounded-full transition-colors overflow-hidden inline-flex items-center justify-center font-normal',
      {
        'bg-green-200 border-green-200 hover:bg-green-100 hover:border-green-100 text-gray-900':
          variant === 'primaryGreen',
        'bg-yellow border-yellow hover:bg-yellow-200 hover:border-yellow-200 text-gray-900':
          variant === 'primaryYellow',
        'bg-gray-100 border-gray-100 hover:bg-gray-50 hover:border-gray-50 text-gray-900':
          variant === 'primaryGray',
        'border-green-400 text-green-500': variant === 'outlineGreen',
        'border-gray-400 hover:bg-gray-100 hover:text-gray-700':
          variant === 'outline',
        'border-transparent underline hover:no-underline': variant === 'link',
        'border-red-500 bg-red-500 text-white': variant === 'danger',
        'bg-black border-black hover:bg-gray-800 hover:border-opacity-90 text-white':
          variant === 'black',
        'text-white bg-facebook border-facebook': variant === 'facebook',
        'text-white bg-google border-google': variant === 'google',
        'opacity-60': disabled,
        'pointer-events-none': disabled || loading,
        'py-2 pr-4': !size,
        'pl-3': !size && Boolean(iconLeft),
        'pl-4': !size && !iconLeft,
        'py-1 px-3 text-sm tracking-wider': size === 'sm',
      }
    ),
    children: (
      <>
        {iconLeft && !loading && <IconLeft size={size}>{iconLeft}</IconLeft>}
        <motion.span
          className="absolute flex outline-none"
          initial={{
            y: -200,
          }}
          animate={
            loading
              ? { y: 0 }
              : {
                  y: -200,
                }
          }
        >
          <LoadingSpinner size={size} />
        </motion.span>
        <motion.span animate={loading ? { y: 200 } : { y: 0 }}>
          {children}
        </motion.span>
        {iconEnd && <IconEnd size={size}>{iconEnd}</IconEnd>}
      </>
    ),
  };
}

export const ButtonAnchor = React.forwardRef<
  HTMLAnchorElement,
  ButtonProps & {
    disabled?: boolean;
  } & React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >
>(
  (
    {
      className,
      variant,
      size,
      disabled,
      children,
      loading,
      iconLeft,
      iconEnd,
      ...props
    },
    ref
  ) => {
    const buttonProps = useButtonProps({
      className,
      variant,
      size,
      disabled,
      children,
      loading,
      iconLeft,
      iconEnd,
    });
    return <a ref={ref} {...buttonProps} {...props} />;
  }
);
