import firebase from '@lib/firebase';
import React, { useState } from 'react';
import { gql } from '@apollo/client';
import {
  CreateUserMutationVariables,
  useCreateUserMutation,
} from '@generated/codegen';
import { useRouter } from 'next/router';
import { trackLoginEvent, trackSignUpEvent } from '@lib/analytics';
import { useCurrentUser } from '@lib/data/current-user';
import { usersPrivateDataDoc } from '@lib/client/controllers/users';
import { useToasts } from '@components/toasts';
import { signInWithPopup } from './signInWithPopup';
import { Input } from '@components/ui/Input';
import { Note } from '@components/ui/Note';
import { useForm } from 'react-hook-form';
import { Button } from '@components/ui/Button';
import { InlineError } from '@components/ui/InlineError';
import {
  AuthMethod,
  getProviderById,
  isValidSignInMethod,
  SocialAuthMethod,
} from './utils';
import { getAuthorizationHeader } from '@lib/graphql/client';
import { instance, URL_UPDATE_SUBSCRIBED } from '../../src/constants/https';
import { useAuthModal } from '@components/auth/AuthModal';

gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

interface AdditionalUserInfoProfileGoogle {
  name?: string;
  given_name?: string;
  family_name?: string;
}

interface AdditionalUserInfoProfileFacebook {
  name?: string;
  first_name?: string;
  last_name?: string;
}

interface ExistingPasswordAccountState {
  type: 'password';
  providerId?: never;
  email: string;
  pendingCredential: firebase.auth.AuthCredential;
}

interface ExistingProviderAccountState {
  type: 'provider';
  providerId: SocialAuthMethod;
  email: string;
  pendingCredential: firebase.auth.AuthCredential;
}

type ExistingAccountState =
  | ExistingPasswordAccountState
  | ExistingProviderAccountState;

function getFirstAndLastNameFromName(name: string) {
  const names = name.split(' ');
  let first_names: string[] | undefined;
  let last_name: string | undefined;
  if (names.length > 1) {
    first_names = [...names];
    last_name = first_names.pop();
  } else {
    last_name = names[0];
  }

  return {
    first_name:
      first_names && first_names.length > 0 ? first_names.join(' ') : undefined,
    last_name,
  };
}

export function useFirebaseAuthAccount(
  onAuthCompleted?: (userCredential: firebase.auth.UserCredential) => void
) {
  let exisitingAccountUi: React.ReactNode = null;
  const toasts = useToasts();
  const router = useRouter();
  const returnTo = router.query.returnTo?.toString();
  const { refetch } = useCurrentUser();
  const { openAuthModal } = useAuthModal();
  const [accountAlreadyExists, setAccountAlreadyExists] =
    useState<ExistingAccountState | null>(null);

  const [firebaseAuthResult, setFirebaseAuthResult] = useState<{
    type: AuthMethod | null;
    loading: boolean;
    error: firebase.auth.Error | null;
  }>({
    type: null,
    loading: false,
    error: null,
  });

  async function onCompleted(
    userCredential: firebase.auth.UserCredential,
    method: 'signin' | 'createUser',
    emailMarketing?: boolean
  ) {
    if (returnTo && returnTo.startsWith('/')) {
      router.push(returnTo);
    }

    if (method === 'createUser' && userCredential.user?.uid) {
      await usersPrivateDataDoc(userCredential.user.uid).set({
        marketing: { notification: true },
      });
      const token = await userCredential.user.getIdToken();
      await instance({ token })
        .put(URL_UPDATE_SUBSCRIBED, {
          uid: userCredential.user.uid,
          subscribed: emailMarketing,
        })
        .catch((error) => {
          openAuthModal(
            'Sorry, we are currently unable to connect to Intercom. We are actively working to resolve the issue! ',
            error
          );
          console.error('Update email preference error: ', error);
        });
    }
    onAuthCompleted && onAuthCompleted(userCredential);
    toasts.addToast({
      type: 'success',
      text: userCredential.user?.email
        ? `You are now signed in with ${userCredential.user.email}`
        : 'Signed in!',
    });
  }

  const [createUserMutation, createUserResult] = useCreateUserMutation({
    onCompleted: async () => {
      refetch();
      trackSignUpEvent(AuthMethod.password);
    },
  });

  /**
   * Create user in firestore
   *
   * TODO: I think this can be handled by auth.user.onCreate
   * @param user
   * @param variables
   * @returns
   */
  async function createUser(
    user: firebase.User | null,
    variables: CreateUserMutationVariables
  ) {
    if (!user) {
      throw new Error('createUser missing user');
    }

    const { authorization } = await getAuthorizationHeader(user);

    return createUserMutation({
      variables,
      context: {
        headers: {
          authorization,
        },
      },
    });
  }

  async function createUserWithEmailAndPassword(values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    emailMarketing: boolean;
  }) {
    const { email, password, firstName, lastName, emailMarketing } = values;
    const type = AuthMethod.password;
    setFirebaseAuthResult({
      type,
      loading: true,
      error: null,
    });

    try {
      const credentials = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      if (credentials.user) {
        await firebase
          .firestore()
          .collection('users')
          .doc(credentials.user.uid)
          .set({ email, firstName, lastName, isArtist: false });
        await onCompleted(credentials, 'createUser', emailMarketing);
      } else {
        throw new Error('Create user returned null');
      }
    } catch (error) {
      setFirebaseAuthResult({ type, loading: false, error });
    }
  }

  /**
   * Sign in with regular email and password
   * User creation is handled by firebase.auth
   * @param email
   * @param password
   */
  async function signInWithEmailAndPassword(email: string, password: string) {
    const type = AuthMethod.password;
    setFirebaseAuthResult({
      type,
      loading: true,
      error: null,
    });
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      refetch();
      onCompleted(userCredential, 'signin');
      trackLoginEvent(type);
    } catch (err) {
      setFirebaseAuthResult({
        type,
        loading: false,
        error: err,
      });
    }
  }

  /**
   * Sign in with Google, Facebook or Apple
   * User creation is handled by firebase.auth
   * @param type
   * @param method
   * @param emailMarketing
   * @returns
   */
  async function handleProviderAuthClick(
    type: SocialAuthMethod,
    method: 'signin' | 'createUser',
    emailMarketing?: boolean
  ) {
    setFirebaseAuthResult({
      type,
      loading: true,
      error: null,
    });

    let userCredential: firebase.auth.UserCredential;

    const provider = getProviderById(type);

    if (type === 'apple.com') {
      provider.setCustomParameters({ locale: 'no_NO' });
    }

    try {
      userCredential = await signInWithPopup(firebase.auth(), provider);
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Step 2.
        // User's email already exists.
        // The pending Google credential.
        const pendingCredential = error.credential;
        // The provider account's email address.
        const email = error.email;
        // Get sign-in methods for this email.
        const methods = await firebase
          .auth()
          .fetchSignInMethodsForEmail(email)
          .then((methods) => methods.filter(isValidSignInMethod));
        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === 'password') {
          // // Asks the user their password.
          setAccountAlreadyExists({
            type: 'password',
            email,
            pendingCredential,
          });
        } else {
          setAccountAlreadyExists({
            type: 'provider',
            providerId: methods[0],
            email,
            pendingCredential,
          });
        }
      } else {
        setFirebaseAuthResult({
          type,
          loading: false,
          error: error,
        });
      }
      return;
    }

    switch (method) {
      case 'signin': {
        trackLoginEvent(type);
        break;
      }
      case 'createUser': {
        trackSignUpEvent(type);
        break;
      }
    }

    const additionalUserInfo = userCredential?.additionalUserInfo;
    if (additionalUserInfo) {
      if (additionalUserInfo.isNewUser && method === 'signin') {
        const profile = additionalUserInfo.profile as any | null;
        const email = profile && profile.email;
        if (
          confirm(
            `We found no account ${
              email ? ` with ${email}` : ''
            }. Would you like to make one?`
          )
        ) {
          let firstName = '';
          let lastName = '';
          switch (type) {
            case 'facebook.com': {
              const profile = additionalUserInfo.profile as
                | AdditionalUserInfoProfileFacebook
                | undefined;
              if (profile) {
                if (profile.first_name) {
                  firstName = profile.first_name;
                }
                if (profile.last_name) {
                  lastName = profile.last_name;
                }
              }
              break;
            }
            case 'google.com': {
              const profile = additionalUserInfo.profile as
                | AdditionalUserInfoProfileGoogle
                | undefined;
              if (profile) {
                if (profile.given_name) {
                  firstName = profile.given_name;
                }
                if (profile.family_name) {
                  lastName = profile.family_name;
                }
              }
              break;
            }
            case 'apple.com':
              {
                // eslint-disable-next-line no-console
                console.log('apple userCredential', userCredential);
              }
              break;
          }
          if (userCredential.user && userCredential.user.displayName) {
            const { first_name, last_name } = getFirstAndLastNameFromName(
              userCredential.user.displayName
            );
            if (!firstName && first_name) {
              firstName = first_name;
            }
            if (!lastName && last_name) {
              lastName = last_name;
            }
          }
          await createUser(userCredential.user, {
            input: {
              firstName,
              lastName,
              email,
            },
          });
        } else {
          await userCredential.user?.delete();
        }
      } else {
        onCompleted(userCredential, method, emailMarketing);
      }
    }
  }

  if (accountAlreadyExists) {
    switch (accountAlreadyExists.type) {
      case 'password': {
        exisitingAccountUi = (
          <ExistingPasswordAccount
            {...accountAlreadyExists}
            onSuccess={(userCredential) => {
              setAccountAlreadyExists(null);
              onCompleted(userCredential, 'signin');
            }}
          />
        );
        break;
      }
      case 'provider': {
        exisitingAccountUi = (
          <ExistingProviderAccount
            {...accountAlreadyExists}
            onSuccess={(userCredential) => {
              setAccountAlreadyExists(null);
              onCompleted(userCredential, 'signin');
            }}
          />
        );
      }
    }
  }

  return {
    exisitingAccountUi,
    handleProviderAuthClick,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    firebaseAuthResult,
    createUserResult,
    loading: createUserResult.loading || firebaseAuthResult.loading,
  };
}

function ExistingPasswordAccount({
  email,
  pendingCredential,
  onSuccess,
}: ExistingPasswordAccountState & {
  onSuccess: (userCredential: firebase.auth.UserCredential) => void;
}) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ password: string }>();
  return (
    <form
      className="space-y-2"
      onSubmit={handleSubmit(async (data) => {
        setLoading(true);
        try {
          const result = await firebase
            .auth()
            .signInWithEmailAndPassword(email, data.password);
          const newCredentials = await result.user?.linkWithCredential(
            pendingCredential
          );
          if (newCredentials) {
            onSuccess(newCredentials);
          } else {
            setError('password', { message: 'Could not connect accounts' });
          }
        } catch (err) {
          setError('password', err);
        } finally {
          setLoading(false);
        }
      })}
    >
      <Note>
        An account registered on {email} already exists. Enter your password to
        connect your {pendingCredential.providerId} account to your email.
      </Note>
      <Input
        id="password"
        label="Password"
        type="password"
        {...register('password')}
        error={errors.password?.message}
        required
      />
      <Button type="submit" variant="primaryYellow" loading={loading}>
        Continue
      </Button>
    </form>
  );
}

function ExistingProviderAccount({
  providerId,
  email,
  pendingCredential,
  onSuccess,
}: ExistingProviderAccountState & {
  onSuccess: (userCredential: firebase.auth.UserCredential) => void;
}) {
  const provider = getProviderById(providerId);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  return (
    <div className="space-y-2">
      <Note>
        An account already exists with {providerId} registered on {email} for{' '}
        {pendingCredential.providerId}.
      </Note>
      {errorMessage && <InlineError>{errorMessage}</InlineError>}
      <Button
        variant="primaryYellow"
        loading={loading}
        onClick={async () => {
          setLoading(true);
          setErrorMessage('');
          try {
            const result = await signInWithPopup(firebase.auth(), provider);
            // Remember that the user may have signed in with an account that has a different email
            // address than the first one. This can happen as Firebase doesn't control the provider's
            // sign in flow and the user is free to login using whichever account they own.
            // Step 4b.
            // Link to Google credential.
            // As we have access to the pending credential, we can directly call the link method.
            if (result.user) {
              const userCredentials = await result.user.linkWithCredential(
                pendingCredential
              );
              // Google account successfully linked to the existing Firebase user.
              if (userCredentials) {
                onSuccess(userCredentials);
              } else {
                setErrorMessage('Could not connect accounts');
              }
            } else {
              setErrorMessage('Could not sign in');
            }
          } catch (err) {
            setErrorMessage(err.message);
          } finally {
            setLoading(false);
          }
        }}
      >
        Continue
      </Button>
    </div>
  );
}
