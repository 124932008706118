import firebase from 'firebase/app';
const { firestore, auth } = firebase;

export const LOADING_EVENTS = 'LOADING_EVENTS';
export const GET_EVENTS = 'GET_EVENTS';
export const EVENTS_RESET = 'EVENTS_RESET';
export const EVENT_ATTENDIE_DATA = 'EVENT_ATTENDIE_DATA';

export const LOADING_EXHIBITIONS = 'LOADING_EXHIBITIONS';
export const GET_EXHIBITIONS = 'GET_EXHIBITIONS';

let attendieSubscriber;
export const getAttendieData = (eventId) => {
  if (attendieSubscriber) {
    attendieSubscriber();
  }

  return (dispatch) => {
    const user = auth().currentUser;
    if (!user) {
      return dispatch({
        type: EVENT_ATTENDIE_DATA,
        payload: null,
      });
    }

    firestore()
      .collection('events')
      .doc(eventId)
      .collection('attendies')
      .doc(user.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          return dispatch({
            type: EVENT_ATTENDIE_DATA,
            payload: {
              attending: false,
            },
          });
        }

        dispatch({
          type: EVENT_ATTENDIE_DATA,
          payload: {
            attending: true,
            ...doc.data(),
          },
        });
      });
  };
};

export const setAttend = (eventId, attending, subscribed) => {
  return (dispatch) => {
    const user = auth().currentUser;
    if (!user) {
      return Promise.resolve();
    }

    const ref = firestore()
      .collection('events')
      .doc(eventId)
      .collection('attendies')
      .doc(user.uid);

    return attending === false
      ? ref.delete()
      : ref.set({ subscribed: subscribed });
  };
};

let unsubscriber;
export const getEvents = () => {
  if (unsubscriber) {
    unsubscriber();
  }

  return (dispatch) => {
    dispatch({
      type: LOADING_EVENTS,
    });

    unsubscriber = firestore()
      .collection('events')
      .onSnapshot((query) => {
        if (query.empty) {
          return dispatch({
            type: GET_EVENTS,
            payload: [],
          });
        }

        const docs = query.docs.filter(
          (doc) => doc.exists && !doc.data().isExhibition
        );

        if (!docs || !docs.length) {
          return dispatch({
            type: GET_EVENTS,
            payload: [],
          });
        }

        const events = docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        dispatch({
          type: GET_EVENTS,
          payload: events,
        });
      });
  };
};

let exUnsubscriber;
export const getEventsAsExhibition = () => {
  if (exUnsubscriber) {
    exUnsubscriber();
  }

  return (dispatch) => {
    dispatch({
      type: LOADING_EXHIBITIONS,
    });

    exUnsubscriber = firestore()
      .collection('events')
      .where('isExhibition', '==', true)
      .onSnapshot((query) => {
        if (query.empty) {
          return dispatch({
            type: GET_EXHIBITIONS,
            payload: [],
          });
        }

        const docs = query.docs.filter((doc) => doc.exists);

        if (!docs || !docs.length) {
          return dispatch({
            type: GET_EXHIBITIONS,
            payload: [],
          });
        }

        const items = docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        Promise.all(
          items.map((item) => {
            return getStudioData(item.studioId).then((studioData) => {
              return {
                ...item,
                ...studioData,
              };
            });
          })
        ).then((hydrated) => {
          dispatch({
            type: GET_EXHIBITIONS,
            payload: hydrated,
          });
        });
      });
  };
};

const getStudioData = (uid) => {
  const ref = firestore().collection('studios').doc(uid);
  return ref.get().then((doc) => {
    if (!doc.exists) {
      return Promise.resolve({ name: '', permalink: '' });
    }

    const data = doc.data();
    return {
      studioName: data.name || '',
      studioPermalink: data.permalink || '',
    };
  });
};
